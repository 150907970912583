<template>
    <footer class="footer-container">
        <p>Copyright © {{year}} The Holistic Centre ® All Rights Reserved.</p>
        <p>Version 1.0.0</p>
    </footer>
</template>

<script>
    export default {
        name: "Footer",
        computed: {
            year() {
                let date = new Date().getFullYear();
                return date;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .footer-container {
        @apply flex flex-row items-center justify-between bg-primary h-auto w-full;
        grid-area: footer;

        p {
            @apply py-2 px-6 text-xs text-white;
        }
    }
</style>