<template>
    <div class="rooms-update-modal">
        <ModalContainer :title="$t('rooms.add_room')" identifier="rooms-update-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                    <FormInputText v-model="$v.room.name.$model" :label="$t('rooms.name')"
                                   :placeholder="$t('rooms.name')" :disabled="is_saving"
                                   :has-error="$v.room.name.$error" autocomplete="off">
                        <template v-slot:errors>
                            <p v-if="!$v.room.name.required">
                                {{ $t('validation.x_is_required', {x: $t('rooms.name')}) }}
                            </p>
                        </template>
                    </FormInputText>

                    <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                        {{ $t('save') }}
                    </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";

export default {
    name: "RoomsUpdateModal",
    components: {Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
    props: {
        room_id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            room: {
                name: null,
            },
            is_saving: false,
        }
    },
    validations: {
        room: {
            name: {required},
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('rooms-update-modal', status);
        },
        save() {
            this.$v.room.$touch();
            if (this.$v.room.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            let payload = {
                name: this.room.name,
            }

            this.$axios.patch(`rooms/${this.room_id}`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('rooms.success_updated'),
                    type: 'success',
                });

                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('rooms.error_update')),
                    type: 'error',
                });
            });
        },
        populate() {
            if (!this.original || !this.room)
                return;

            this.$v.room.name.$model = this.original.attributes.name;
        },
        async retrieveOriginalRoom() {
            this.is_loading_original = false;
            await this.$axios.get(`rooms/${this.room_id}`)
                .then(({data}) => {
                    this.is_loading_original = false;
                    this.original = data.data;
                })
                .catch(e => {
                    this.is_loading_original = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('rooms.error_retrieve')),
                        type: 'error',
                    });
                });
        },
    },
    async mounted() {
        await this.retrieveOriginalRoom();
        this.populate();
    }
}
</script>
