<template>
    <div class="unavailable-time-slot-create-modal" v-if="!is_loading_therapists">
        <ModalContainer :title="$t('profile.add_unavailable_time_slot')" identifier="unavailable-time-slot-create-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormInputText v-if="from_profile"
                               v-model="therapistName" identifier="name"
                               :label="$t('therapists.therapist')"
                               :placeholder="$t('therapists.therapist')" :disabled="true">
                </FormInputText>

                <FormInputSelect v-else
                                 v-model="$v.unavailability.therapist.$model" identifier="therapist"
                                 :label="$t('bookings.therapist')" :options="therapistOptions"
                                 :placeholder="$t('bookings.therapist')" :disabled="is_saving"
                                 :has-error="$v.unavailability.therapist.$error" track-by="id"
                                 display-label="name" :multiple="false" class="select">
                    <template v-slot:errors>
                        <p v-if="!$v.unavailability.therapist.required">
                            {{ $t('validation.x_is_required', {x: $t('bookings.therapist')}) }}
                        </p>
                    </template>
                </FormInputSelect>

                <FormGroupTwo>
                    <FormInputDateTime v-model="$v.unavailability.from.$model" identifier="from"
                                       :label="$t('bookings.date_time_from')"
                                       :placeholder="$t('bookings.date_time_from')"
                                       :disabled="is_saving" :minute-interval="15"
                                       :has-error="$v.unavailability.from.$error"
                                       :min-date="todayDate" outputFormat="YYYY-MM-DD HH:mm">
                        <template v-slot:errors>
                            <p v-if="!$v.unavailability.from.required">
                                {{ $t('validation.x_is_required', {x: $t('bookings.date_time_from')}) }}
                            </p>
                            <p v-else-if="is_time_equal">
                                {{ $t('bookings.time_cant_be_same') }}
                            </p>
                            <p v-else-if="is_date_different">
                                {{ $t('bookings.date_cant_be_different') }}
                            </p>
                        </template>
                    </FormInputDateTime>
                    <FormInputDateTime v-model="$v.unavailability.to.$model" identifier="to" :label="$t('bookings.date_time_to')"
                                       :placeholder="$t('bookings.date_time_to')"
                                       :disabled="is_saving" :minute-interval="15"
                                       :has-error="$v.unavailability.to.$error" :min-date="minReturnDate"
                                       :max-date="maxReturnDate" outputFormat="YYYY-MM-DD HH:mm">
                        <template v-slot:errors>
                            <p v-if="!$v.unavailability.to.required">
                                {{ $t('validation.x_is_required', {x: $t('bookings.date_time_to')}) }}
                            </p>
                        </template>
                    </FormInputDateTime>
                </FormGroupTwo>

                <FormInputText v-model="$v.unavailability.note.$model" :label="$t('bookings.note')"
                               :placeholder="$t('bookings.note')" :disabled="is_saving"
                               :use-textarea="true" :large-textarea="true"
                               :has-error="$v.unavailability.note.$error" autocomplete="off">
                    <template v-slot:errors>
                    </template>
                </FormInputText>


                <Button type="submit" className="--primary --small ml-auto" :class="{spinner: is_saving}">
                    {{ $t('add') }}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import {required, email, requiredIf} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";
import FormInputDateTime from "../form/FormInputDateTime";

export default {
    name: "UnavailableTimeSlotCreateModal",
    components: {FormInputPassword, FormInputDateTime, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
    props: {
        from_profile: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            unavailability: {
                to: null,
                from: null,
                note: null,
                therapist: null,
            },
            therapistName: null,
            is_saving: false,
            is_loading_original: false,
            is_loading_therapists: false,
            therapistOptions: [],
        }
    },
    validations: {
        unavailability: {
            from: {required},
            to: {required},
            note: {},
            therapist: {
                required: requiredIf(function() {
                    return this.from_profile !== true;
                }),
            }
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('unavailable-time-slot-create-modal', status);
        },
        save() {
            this.$v.unavailability.$touch();
            if (this.$v.unavailability.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            const payload = {}

            payload.from = this.unavailability.from;
            payload.to = this.unavailability.to;

            if (this.from_profile)
                payload.therapist_id = this.$store.getters.user.id;
            else
                payload.therapist_id = this.unavailability.therapist.id;

            payload.user_id = this.$store.getters.user.id;

            if(payload.from === payload.to){
                this.is_time_equal = true;
                this.is_saving = false;
                return;
            }

            if(payload.from.substring(0,10) !== payload.to.substring(0,10)){
                this.is_date_different = true;
                this.is_saving = false;
                return;
            }

            if (this.unavailability.note)
                payload.note = this.unavailability.note

            this.$axios.post(`unavailabilities`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('profile.unavailable_time_slot_success_created'),
                    type: 'success',
                });

                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('profile.unavailable_time_slot_failed_create')),
                    type: 'error',
                });
            });
        },
        async retrieveTherapists() {
            this.is_loading_therapists = false;
            await this.$axios.get('therapists/list')
                .then(({data}) => {
                    this.therapistOptions = data.data.map(x => ({
                        id: x.id,
                        name: x.attributes.name,
                        column: x.attributes.column
                    }))
                    this.is_loading_therapists = false;
                })
                .catch(e => {
                    this.is_loading_therapists = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('therapists.error_retrieve')),
                        type: 'error',
                    });
                });
        },
    },
    async mounted () {
        if (!this.from_profile)
            await this.retrieveTherapists();
        else
            this.therapistName = this.$store.getters.user.attributes.name
    },
    computed: {
        todayDate() {
            let date = new Date();
            return this.$moment(date).format('YYYY-MM-DD HH:mm');
        },
        minReturnDate() {
            return this.$moment(this.unavailability.from).add(15, 'minutes').format('YYYY-MM-DD HH:mm');
        },
        maxReturnDate() {
            return this.$moment(this.unavailability.from).endOf('day').format('YYYY-MM-DD HH:mm');
        }
    },
}
</script>
