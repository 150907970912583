var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-container"},[_c('Headbar',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('Button',{attrs:{"className":"--primary --small","onclick":_vm.toggleCreate}},[_vm._v(" "+_vm._s(_vm.$t('therapists.add_therapist'))+" ")])]},proxy:true},{key:"right",fn:function(){return [_c('Search',{staticClass:"search-desktop",attrs:{"placeholder":_vm.$t('therapists.search_therapists')},on:{"search":_vm.search}}),_c('button',{staticClass:"btn-search-mobile btn-icon-only",on:{"click":function($event){_vm.headbarExpanded = _vm.headbarExpanded === 'search' ? null : 'search'}}},[(_vm.headbarExpanded === 'search')?_c('font-awesome-icon',{attrs:{"icon":['far', 'times']}}):_c('font-awesome-icon',{attrs:{"icon":['far', 'search']}})],1)]},proxy:true},{key:"expanded",fn:function(){return [_c('div',{staticClass:"headbar-expanded-container"},[(_vm.headbarExpanded === 'search')?_c('Search',{attrs:{"placeholder":_vm.$t('therapists.search_therapists')},on:{"search":_vm.search}}):_vm._e()],1)]},proxy:true}])}),_c('main',[_c('vue-good-table',{attrs:{"mode":"remote","styleClass":"vgt-table vgt-custom","columns":_vm.columns,"rows":_vm.therapists,"isLoading":_vm.is_loading_therapists,"search-options":{
                enabled: false,
            },"pagination-options":{
                enabled: true,
                mode: 'records',
                dropdownAllowAll: false,
                perPage: 15,
                perPageDropdownEnabled: false,
                rowsPerPageLabel: _vm.$t('x_per_page', {x: _vm.$t('therapists.therapists')}),
            },"sort-options":{
              enabled: true,
              multipleColumns: true,
            },"totalRows":_vm.totalRecords},on:{"update:isLoading":function($event){_vm.is_loading_therapists=$event},"update:is-loading":function($event){_vm.is_loading_therapists=$event},"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'role')?_c('div',[_c('p',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(props.row.relationships.role.data[0].attributes.name))])]):(props.column.field === 'after')?_c('div',{staticClass:"td-after"},[(_vm.$store.getters.hasPermission('update therapists'))?_c('Button',{attrs:{"className":"--secondary --outline --mini --big-text","onclick":()=>_vm.toggleUpdate(props.row)}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'pencil']}})],1):_vm._e(),(_vm.$store.getters.hasPermission('destroy therapists'))?_c('Button',{attrs:{"className":"--secondary --outline --mini --big-text","onclick":()=>_vm.toggleDelete(props.row)}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'trash']}})],1):_vm._e()],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }