<template>
    <div class="unavailable-time-slot-update-modal">
        <ModalContainer :title="$t('profile.edit_unavailable_time_slot')" identifier="unavailable-time-slot-update-modal" :closable="true">
            <loading-screen v-if="is_loading_original || is_loading_therapists || !this.original"/>
            <Form v-else class="form" @submit="save" :disabled="is_saving">
                <FormInputText v-if="from_profile"
                               v-model="original.relationships.therapist.data.attributes.name" identifier="name"
                               :label="$t('therapists.therapist')"
                               :placeholder="$t('therapists.therapist')" :disabled="true">
                </FormInputText>

                <FormInputSelect v-else
                                 v-model="$v.unavailability.therapist.$model" identifier="therapist"
                                 :label="$t('bookings.therapist')" :options="therapistOptions"
                                 :placeholder="$t('bookings.therapist')" :disabled="is_saving"
                                 :has-error="$v.unavailability.therapist.$error" track-by="id"
                                 display-label="name" :multiple="false" class="select">
                    <template v-slot:errors>
                        <p v-if="!$v.unavailability.therapist.required">
                            {{ $t('validation.x_is_required', {x: $t('bookings.therapist')}) }}
                        </p>
                    </template>
                </FormInputSelect>

                <FormGroupTwo>
                    <FormInputDateTime v-model="$v.unavailability.from.$model" identifier="from"
                                       :label="$t('bookings.date_time_from')" :placeholder="$t('bookings.date_time_from')"
                                       :disabled="is_saving" :minute-interval="15"
                                       :has-error="$v.unavailability.from.$error"
                                       :min-date="todayDate">
                        <template v-slot:errors>
                            <p v-if="!$v.unavailability.from.required">
                                {{ $t('validation.x_is_required', {x: $t('bookings.date_time_from')}) }}
                            </p>
                        </template>
                    </FormInputDateTime>
                    <FormInputDateTime v-model="$v.unavailability.to.$model" identifier="to" :label="$t('bookings.date_time_to')"
                                       :placeholder="$t('bookings.date_time_to')" :disabled="is_saving" :minute-interval="15"
                                       :has-error="$v.unavailability.to.$error || $moment(unavailability.to).isBefore($moment(unavailability.from))"
                                       :max-date="maxReturnDate" :min-date="minReturnDate">
                        <template v-slot:errors>
                            <p v-if="!$v.unavailability.to.required">
                                {{ $t('validation.x_is_required', {x: $t('bookings.date_time_to')}) }}
                            </p>
                            <p v-else-if="$v.unavailability.to.required && $moment(unavailability.to).isBefore($moment(unavailability.from))">
                                {{ $t('bookings.date_to_cant_be_before') }}
                            </p>
                        </template>
                    </FormInputDateTime>
                </FormGroupTwo>

                <FormInputText v-model="$v.unavailability.note.$model" :label="$t('bookings.note')"
                               :placeholder="$t('bookings.note')" :disabled="is_saving"
                               :use-textarea="true" :large-textarea="true"
                               :has-error="$v.unavailability.note.$error" autocomplete="off" >
                    <template v-slot:errors>
                    </template>
                </FormInputText>

                <div class="buttons-container">
                    <Button v-if="$store.getters.hasRole('administrator')" class="remove-button" className="--negative --small" :class="{disabled: is_saving}" :onclick="removeUnavailability">
                        {{ $t('remove') }}
                    </Button>

                    <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                        {{ $t('save') }}
                    </Button>
                </div>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import FormInputDateTime from "../form/FormInputDateTime";
import {required, email, requiredIf} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";
import LoadingScreen from "@/components/LoadingScreen";
import ConfirmModal from "@/components/modal/ConfirmModal.vue";

export default {
    name: "UnavailableTimeSlotUpdateModal",
    components: {
        LoadingScreen,
        FormInputPassword, Button, FormInputSelect, FormInputDateTime, FormGroupTwo, FormInputText, Form, ModalContainer},
    props: {
        unavailability_id: {
            type: Number,
            required: true
        },
        from_profile: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            original: null,
            unavailability: {
                to: null,
                from: null,
                note: null,
                therapist: null,
            },
            date_return_before_error: false,
            is_saving: false,
            is_loading_original: false,
            is_loading_therapists: false,
            therapistOptions: [],
        }
    },
    validations: {
        unavailability: {
            from: {required},
            to: {required},
            note: {},
            therapist: {},
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('unavailable-time-slot-update-modal', status);
        },
        removeUnavailability() {
            let self = this;
            this.$modal.show(
                ConfirmModal, {
                    message: this.$t('bookings.prompt_delete_unavailability')
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.$axios.delete(`/unavailabilities/${this.unavailability_id}`)
                                .then(({data}) => {
                                    this.is_saving = false;
                                    this.close(true);
                                    self.close(true);
                                })
                                .catch(e => {
                                    this.$notify({
                                        title: this.$t('error'),
                                        text: this.$t('bookings.error_remove_unavailability'),
                                        type: 'error',
                                    });
                                })
                        }
                    }
                });
        },
        save() {
            this.$v.unavailability.$touch();
            if (this.$v.unavailability.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            const payload = {}

            payload.from = this.unavailability.from;
            payload.to = this.unavailability.to;

            if (this.from_profile)
                payload.therapist_id = this.$store.getters.user.id;
            else
                payload.therapist_id = this.unavailability.therapist.id;

            payload.user_id = this.$store.getters.user.id;

            if(this.$moment(this.unavailability.to).isBefore(this.$moment(this.unavailability.from))) {
                this.date_return_before_error = true;

                this.is_saving = false;
                return;
            } else {
                this.date_return_before_error = false;
            }

            //
            // if(payload.from.substring(0,10) !== payload.to.substring(0,10)){
            //     this.is_date_different = true;
            //     this.is_saving = false;
            //     return;
            // }


            if (this.unavailability.note)
                payload.note = this.unavailability.note

            this.$axios.patch(`unavailabilities/${this.unavailability_id}`, payload).then(async ({data}) => {
                this.$notify({
                    text: this.$t('profile.unavailable_time_slot_success_updated'),
                    type: 'success',
                });

                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('profile.unavailable_time_slot_failed_update')),
                    type: 'error',
                });
            });
        },
        async retrieveOriginalUnavailability() {
            this.is_loading_original = true;

            await this.$axios.get(`unavailabilities/${this.unavailability_id}`)
                .then(async({data}) => {
                    this.is_loading_original = false;
                    this.original = data.data;
                })
                .catch(e => {
                    this.is_loading_original = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('profile.unavailable_time_slot_failed_retrieve')),
                        type: 'error',
                    });
                });
        },
        async retrieveTherapists() {
            this.is_loading_therapists = true;
            await this.$axios.get('therapists/list')
                .then(({data}) => {
                    this.therapistOptions = data.data.map(x => ({
                        id: x.id,
                        name: x.attributes.name,
                        column: x.attributes.column
                    }))
                    this.is_loading_therapists = false;
                })
                .catch(e => {
                    this.is_loading_therapists = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('therapists.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        populate() {
            if (!this.original) return;

            this.unavailability.from = this.$moment(this.original.attributes.from).format('YYYY-MM-DD HH:mm');
            this.unavailability.to = this.$moment(this.original.attributes.to).format('YYYY-MM-DD HH:mm');

            if (this.original.attributes.note)
                this.$v.unavailability.note.$model = this.original.attributes.note;

            if (!this.from_profile)
                this.$v.unavailability.therapist.$model = this.therapistOptions.find(x => x.id === this.original.relationships.therapist.data.id)
        },
    },
    async mounted() {
        if (!this.from_profile)
            await this.retrieveTherapists();

        await this.retrieveOriginalUnavailability();
        await this.populate();
    },
    computed: {
        todayDate() {
            let date = new Date();
            return this.$moment(date).format('YYYY-MM-DD');
        },
        minReturnDate() {
            return this.$moment(this.unavailability.from).format('YYYY-MM-DD');
        },
        maxReturnDate() {
            return this.$moment(this.unavailability.from).endOf('day').format('YYYY-MM-DD HH:mm');
        }
    },
}
</script>

<style lang="scss">
.buttons-container {
    @apply flex flex-row ml-auto;

    .remove-button {
        @apply mr-4;
    }
}
</style>
