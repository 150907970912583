<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <Button className="--primary --small --wider headbar-fix"
                        :class="{spinner: is_saving}" :onclick="save">
                    {{ $t('save') }}
                </Button>
            </template>
            <template v-slot:right>

            </template>
        </Headbar>
        <main v-if="!is_loading_original">
            <div class="info-container" v-if="user">
                <Form class="form" :disabled="!$store.getters.hasPermission('update users') || is_saving">
                    <SectionHeader :title="$t('profile.personal_information')"></SectionHeader>
                    <div class="form-body">
                        <FormGroupTwo>
                            <FormInputText v-model="$v.user.name.$model" identifier="name"
                                           :label="$t('users.name')"
                                           :placeholder="$t('users.name')" :disabled="is_saving"
                                           :has-error="$v.user.name.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.user.name.required">
                                        {{ $t('validation.x_is_required', {x: $t('users.name')}) }}
                                    </p>
                                </template>
                            </FormInputText>
                            <FormInputText v-model="$v.user.mobile_number.$model" identifier="mobile_number"
                                           :label="$t('users.mobile_number')"
                                           :placeholder="$t('users.mobile_number')" :disabled="is_saving"
                                           :has-error="$v.user.mobile_number.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.user.mobile_number.required">
                                        {{ $t('validation.x_is_required', {x: $t('users.mobile_number')}) }}
                                    </p>
                                </template>
                            </FormInputText>
                            <FormInputText v-model="$v.user.email.$model" identifier="email"
                                           :label="$t('users.email')"
                                           :placeholder="$t('users.email')" :disabled="is_saving"
                                           :has-error="$v.user.email.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.user.email.required">
                                        {{ $t('validation.x_is_required', {x: $t('users.email')}) }}
                                    </p>
                                </template>
                            </FormInputText>
                        </FormGroupTwo>
                    </div>
                </Form>
                <Form class="form" :disabled="!$store.getters.hasPermission('update users') || is_saving">
                    <SectionHeader :title="$t('profile.change_password')"></SectionHeader>
                    <div class="form-body">
                        <FormGroupTwo>

                            <FormInputPassword v-model="$v.user.password.$model" identifier="password"
                                               :label="$t('users.password')"
                                               :placeholder="$t('users.password')" :disabled="is_saving" type="password"
                                               :has-error="$v.user.password.$error" autocomplete="off">
                                <template v-slot:errors>
                                    <p v-if="!$v.user.password.required">
                                        {{ $t('validation.x_is_required', {x: $t('users.password')}) }}
                                    </p>
                                </template>
                            </FormInputPassword>
                        </FormGroupTwo>

                    </div>
                </Form>
            </div>
            <div v-if="this.$store.getters.hasRole('therapist')" class="unavailable-time-slots-container">
                <div class="title-container">
                    <h1>{{ $t('profile.my_unavailable_time_slots') }}</h1>
                    <Button className="--primary --small" :onclick="()=>toggleAddUnavailableTimeSlot()">
                        {{ $t('profile.add_unavailable_time_slot') }}
                    </Button>
                </div>
                <vue-good-table
                    mode="remote"
                    styleClass="vgt-table vgt-custom"
                    :columns="unavailableTimeslotColumns"
                    :rows="unavailabilities"
                    :isLoading.sync="is_loading_unavailabilities"
                    :search-options="{enabled: false}"
                    :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    dropdownAllowAll: false,
                    perPage: 15,
                    perPageDropdownEnabled: false,
                    rowsPerPageLabel: $t('x_per_page', {x: $t('profile.availabilities')}),
                }"
                    :sort-options="{
                  enabled: true,
                  multipleColumns: true,
                }"
                    :totalRows="totalRecordsUnavailabilities"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange">
                    <template slot="table-row" slot-scope="props">
                        <div v-if="props.column.field === 'status'">
                            <div class="not-available-container">
                                <div class="not-available-circle"></div>
                                <p>{{$t('profile.not_available')}}</p>
                            </div>
                        </div>
                        <div v-else-if="props.column.field === 'attributes.from'">
                            <p style="text-transform: capitalize;">{{ $moment(props.row.attributes.from).format('DD/MM/YYYY - HH:mm') }}</p>
                        </div>
                        <div v-else-if="props.column.field === 'attributes.to'">
                            <p style="text-transform: capitalize;">{{ $moment(props.row.attributes.to).format('DD/MM/YYYY - HH:mm') }}</p>
                        </div>
                        <div v-else-if="props.column.field === 'after'" class="td-after">
                            <Button v-if="$store.getters.hasPermission('update unavailabilities')" className="--secondary --outline --mini --big-text"
                                    :onclick="()=>toggleUpdateUnavailableTimeSlot(props.row)">
                                <font-awesome-icon :icon="['fal', 'pencil']"/>
                            </Button>
                            <Button v-if="$store.getters.hasPermission('destroy unavailabilities')"
                                className="--secondary --outline --mini --big-text"
                                :onclick="()=>toggleDeleteUnavailableTimeSlot(props.row)">
                                <font-awesome-icon :icon="['fal', 'trash']"/>
                            </Button>
                        </div>
                        <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                    </template>
                </vue-good-table>
            </div>
        </main>
    </div>
</template>

<script>
import _ from 'lodash';
import Headbar from "../components/headbar/Headbar";
import Form from "../components/form/Form";
import SectionHeader from "../components/SectionHeader";
import Button from "../components/Button";
import FormInputText from "../components/form/FormInputText";
import FormInputSelect from "../components/form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import FormGroupTwo from "../components/form/FormGroupTwo";
import FormGroupThree from "../components/form/FormGroupThree";
import FormInputPassword from "../components/form/FormInputPassword";
import FormInputTextSelect from "../components/form/FormInputTextSelect";
import UnavailableTimeSlotCreateModal from "../components/therapists/UnavailableTimeSlotCreateModal";
import ConfirmModal from "../components/modal/ConfirmModal";
import RoomsUpdateModal from "../components/rooms/RoomsUpdateModal";
import UnavailableTimeSlotUpdateModal from "../components/therapists/UnavailableTimeSlotUpdateModal";
import BookingsCreateModal from "../components/bookings/BookingsCreateModal";


export default {
    name: "users-update-page",
    components: {
        FormGroupTwo,
        FormGroupThree,
        FormInputText,
        FormInputPassword,
        FormInputSelect,
        Button,
        SectionHeader,
        Form,
        Headbar,
        FormInputTextSelect,
    },
    data() {
        const unavailableTimeslotColumns = [
            {
                label: this.$t('bookings.status'),
                field: 'status',
                sortable: false,
            },
            {
                label: this.$t('bookings.date_time_from'),
                field: 'attributes.from',
                sortable: false,
            },
            {
                label: this.$t('bookings.date_time_to'),
                field: 'attributes.to',
                sortable: false,
            },
        ];

        if (this.$store.getters.hasAnyPermission(['update unavailabilities', 'destroy unavailabilities']))
            unavailableTimeslotColumns.push({
                label: this.$t('bookings.actions'),
                field: 'after',
                tdClass: 'td-after',
                sortable: false
            });

        return {
            original: null,
            user: {
                name: null,
                mobile_number: null,
                email: null,
                password: null,
            },
            unavailableTimeslotColumns: unavailableTimeslotColumns,
            unavailabilities: [],
            serverParams: {sorting: []},
            totalRecordsUnavailabilities: null,
            is_saving: false,
            is_loading_original: true,
            is_loading_unavailabilities: false,
        }
    },
    validations: {
        user: {
            name: {required},
            mobile_number: {required},
            email: {required, email},
            password: {},
        }
    },
    methods: {
        populate() {
            if (!this.original || !this.user)
                return;

            this.$v.user.name.$model = this.original.attributes.name;
            this.$v.user.mobile_number.$model = this.original.attributes.mobile_number;
            this.$v.user.email.$model = this.original.attributes.email;

            this.is_loading_original = false;
        },
        async retrieveOriginalUser() {
            this.is_loading_original = true;

            await this.$axios.get(`profile`)
                .then(({data}) => {
                    this.original = data.data;
                })
                .catch(e => {
                    this.is_loading_original = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('users.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        save() {
            this.$v.user.$touch();
            if (this.$v.user.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            const payload = {};

            if (this.original.attributes.name !== this.$v.user.name.$model)
                payload.name = this.$v.user.name.$model;

            if (this.original.attributes.mobile_number !== this.$v.user.mobile_number.$model)
                payload.mobile_number = this.$v.user.mobile_number.$model;

            if (this.original.attributes.email !== this.$v.user.email.$model)
                payload.email = this.$v.user.email.$model;

            if (this.$v.user.password.$model) {
                payload.password = this.$v.user.password.$model;
            }

            this.$axios.patch(`profile`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('profile.success_updated'),
                    type: 'success',
                });
                this.is_saving = false;
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('profile.error_update')),
                    type: 'error',
                });
            });
        },

        async retrieveUnavailableTimeSlots() {
            this.is_loading_unavailabilities = true;

            const encodedSorting = this.serverParams.sorting.map(f => btoa(JSON.stringify(f)));

            let filters = {
                therapist_id: this.$store.getters.user.id
            }

            this.$axios.get('unavailabilities', {params: {...filters, ...this.serverParams, sorting: encodedSorting}})
                .then(({data}) => {
                    this.unavailabilities = data.data;
                    this.totalRecordsUnavailabilities = data.meta.total;
                    this.is_loading_unavailabilities = false;
                })
                .catch(e => {
                    this.is_loading_unavailabilities = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('profile.unavailable_time_slot_failed_retrieve')),
                        type: 'error',
                    });
                });
        },

        async toggleAddUnavailableTimeSlot() {
            this.$modal.show(
                UnavailableTimeSlotCreateModal, {
                    from_profile: true
                },
                {
                    name: 'unavailable-time-slot-create-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': async (e) => {
                        if (e.params === true) {
                            await this.retrieveOriginalUser();
                            await this.populate();
                            await this.retrieveUnavailableTimeSlots();
                        }
                    }
                }
            );
        },

        toggleDeleteUnavailableTimeSlot(unavailability) {
            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('profile.delete_unavailable_time_slot'),
                    message: this.$t('profile.unavailable_time_slot_prompt_delete'),
                    confirmText: this.$t('delete'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.is_deleting = true;
                            this.$axios.delete(`unavailabilities/${unavailability.id}`)
                                .then(async ({data}) => {
                                    this.is_deleting = false;
                                    this.$notify({
                                        text: this.$t('profile.unavailable_time_slot_success_deleted'),
                                        type: 'success',
                                    });
                                    await this.retrieveOriginalUser();
                                    await this.populate();
                                    await this.retrieveUnavailableTimeSlots();
                                })
                                .catch(e => {
                                    this.is_deleting = false;

                                    this.$notify({
                                        title: this.$t('error'),
                                        text: this.$larerror(e.response.data, this.$t('profile.unavailable_time_slot_failed_delete')),
                                        type: 'error',
                                    });
                                });
                        }
                    }
                }
            );
        },

        async toggleUpdateUnavailableTimeSlot(unavailability) {
            this.$modal.show(
                UnavailableTimeSlotUpdateModal,
                {
                    unavailability_id: unavailability.id,
                    from_profile: true,
                },
                {
                    name: 'unavailable-time-slot-update-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': async (e) => {
                        if (e.params === true) {
                            await this.retrieveOriginalUser();
                            await this.populate();
                            await this.retrieveUnavailableTimeSlots();
                        }
                    }
                }
            );
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.retrieveUnavailableTimeSlots();
        },
        onSortChange(params) {
            const sorts = [];

            if (!params || !params.length) {
                this.updateParams({sorting: []});
                return this.retrieveUnavailableTimeSlots();
            }

            params.forEach(p => {
                if (!p.type || p.type === 'none')
                    return;

                let sort_by = null;
                let sort_order = p.type;
                if (p.field === 'attributes.name')
                    sort_by = 'name';
                else if (p.field === 'attributes.email')
                    sort_by = 'email';
                else if (p.field === 'role')
                    sort_by = 'role';
                else
                    sort_by = p.field.split('.')[1];

                sorts.push({sort_order, sort_by})
            });

            this.updateParams({sorting: sorts});
            this.retrieveUnavailableTimeSlots();
        },
        removeParam(param) {
            this.$delete(this.serverParams, param);
        },
    },

    async mounted() {
        await this.retrieveOriginalUser();
        await this.retrieveUnavailableTimeSlots();
        this.populate();
    },

    head() {
        return {
            title: {
                inner: this.$t('profile.user_account')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
main {
    padding: 0 !important;
}

.page-container {

    .headbar-fix {
        @apply my-0.5;
    }

    main {
        .info-container {
            @apply flex flex-row flex-wrap border-b-2 border-primary w-full py-7 px-6;

            @screen md {
                @apply px-9;
            }

            .form {
                @apply w-full max-w-3xl mx-auto;

                @screen xl {
                    width: calc(50% - 1rem);

                    &:nth-child(odd) {
                        @apply mr-4 ml-0;
                    }

                    &:nth-child(even) {
                        @apply ml-4 mr-0;
                    }
                }

                .form-body {
                    @apply px-8 pt-7 pb-2.5;

                }

                .email-address, .address {
                    @apply w-65%;
                }
            }
        }

        .unavailable-time-slots-container {
            @apply px-10 mt-5 mb-5;

            .title-container {
                @apply flex flex-row justify-between mb-4;

                h1 {
                    @apply text-black font-bold text-2xl;
                }
            }

            .not-available-container {
                @apply flex flex-row items-center gap-x-2;

                .not-available-circle {
                    @apply bg-black w-4 h-4 rounded-full;
                }
            }

            .td-after {
                @apply flex flex-row;

                & > * {
                    @apply mr-3;

                    &:last-child {
                        @apply mr-0;
                    }
                }
            }
        }
    }
}
</style>