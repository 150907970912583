<template>
    <div class="services-create-modal">
        <ModalContainer :title="$t('services.add_service')" identifier="services-create-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormGroupTwo>
                    <FormInputText v-model="$v.service.name.$model" :label="$t('services.name')"
                                   :placeholder="$t('services.name')" :disabled="is_saving"
                                   :has-error="$v.service.name.$error" autocomplete="off">
                        <template v-slot:errors>
                            <p v-if="!$v.service.name.required">
                                {{ $t('validation.x_is_required', {x: $t('services.name')}) }}
                            </p>
                        </template>
                    </FormInputText>

                    <FormInputText v-model="$v.service.price.$model" identifier="name" :label="$t('services.price')"
                                   :placeholder="$t('services.price')" :disabled="is_saving"
                                   :has-error="$v.service.price.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.service.price.required">
                                {{ $t('validation.x_is_required', {x: $t('services.price')}) }}
                            </p>
                        </template>
                    </FormInputText>
                </FormGroupTwo>

                <FormInputDateTime v-model="$v.service.duration.$model" identifier="duration" :label="$t('services.duration')"
                                   :placeholder="$t('services.duration')" :only-time="true" format="HH:mm" formatted="HH:mm"
                                   :disabled="is_saving" :minute-interval="5"
                                   :hasError="$v.service.duration.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.service.duration.required">
                            {{ $t('validation.x_is_required', {x: $t('services.duration')}) }}
                        </p>
                    </template>
                </FormInputDateTime>

                <FormInputText v-model="$v.service.items.$model" identifier="name" :label="$t('services.items_to_bring')"
                               :placeholder="$t('services.items_to_bring')" :disabled="is_saving"/>

                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{ $t('save') }}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import FormInputDateTime from "../form/FormInputDateTime";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";

export default {
    name: "ServicesCreateModal",
    components: {Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer, FormInputDateTime},
    data() {
        return {
            service: {
                name: null,
                price: null,
                duration: null,
                items: null,
            },
            is_saving: false,
        }
    },
    validations: {
        service: {
            name: {required},
            price: {required},
            duration: {required},
            items: {},
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('services-create-modal', status);
        },
        save() {
            this.$v.service.$touch();
            if (this.$v.service.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            const payload = Object.assign({}, this.$v.service.$model);

            payload.duration = this.$moment(payload.duration).format('HH:mm')

            if(!this.service.items)
                delete payload.items;

            this.$axios.post(`services`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('services.success_created'),
                    type: 'success',
                });

                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('services.error_create')),
                    type: 'error',
                });
            });
        },
    },
}
</script>
