<template>
    <div class="clients-update-modal">
        <ModalContainer :title="$t('clients.edit_client')" identifier="clients-update-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormGroupTwo>
                    <FormInputText v-model="$v.client.name.$model" identifier="name" :label="$t('clients.name')"
                                   :placeholder="$t('clients.name')" :disabled="is_saving"
                                   :has-error="$v.client.name.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.client.name.required">
                                {{$t('validation.x_is_required',{x: $t('clients.name')})}}
                            </p>
                        </template>
                    </FormInputText>

                    <FormInputText v-model="$v.client.mobile_number.$model" identifier="mobile_number" :label="$t('clients.mobile_number')"
                                   :placeholder="$t('clients.mobile_number')" :disabled="is_saving"
                                   :has-error="$v.client.mobile_number.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.client.mobile_number.required">
                                {{$t('validation.x_is_required',{x: $t('clients.mobile_number')})}}
                            </p>
                        </template>
                    </FormInputText>
                </FormGroupTwo>

                <FormInputText v-model="$v.client.note.$model" identifier="note" :label="$t('clients.note')"
                               :placeholder="$t('clients.note')" :disabled="is_saving"
                               :has-error="$v.client.note.$error" autocomplete="off"
                               :use-textarea="true" :large-textarea="true"/>

                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{$t('save')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
    import _ from 'lodash';
    import ModalContainer from "../modal/ModalContainer";
    import Form from "../form/Form";
    import FormInputText from "../form/FormInputText";
    import FormGroupTwo from "../form/FormGroupTwo";
    import FormInputSelect from "../form/FormInputSelect";
    import {required, email} from 'vuelidate/lib/validators'
    import Button from "../Button";
    import FormInputPassword from "@/components/form/FormInputPassword";

    export default {
        name: "ClientsUpdateModal",
        components: {FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
        props: {
            client_id: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                original: null,
                client: {
                    name: null,
                    mobile_number: null,
                    note: null,
                },
                is_saving: false,
                is_loading_original: false,
            }
        },
        validations: {
            client: {
                name: {required},
                mobile_number: {required},
                note: {},
            }
        },
        methods: {
            close(status) {
                this.$modal.hide('clients-update-modal', status);
            },
            save() {
                this.$v.client.$touch();
                if (this.$v.client.$anyError || this.is_saving)
                    return;

                this.is_saving = true;

                let payload = {}

                if (this.original.attributes.name !== this.$v.client.name.$model)
                    payload.name = this.$v.client.name.$model;

                if (this.original.attributes.mobile_number !== this.$v.client.mobile_number.$model)
                    payload.mobile_number = this.$v.client.mobile_number.$model;

                if (this.original.attributes.note !== this.$v.client.note.$model)
                    payload.note = this.$v.client.note.$model;

                this.$axios.patch(`clients/${this.client_id}`, payload).then(({data}) => {
                    this.$notify({
                        text: this.$t('clients.success_updated'),
                        type: 'success',
                    });

                    this.is_saving = false;
                    this.close(true);
                }).catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('clients.error_update')),
                        type: 'error',
                    });
                });
            },
            populate() {
                if (!this.original || !this.client)
                    return;

                this.$v.client.name.$model = this.original.attributes.name;
                this.$v.client.mobile_number.$model = this.original.attributes.mobile_number;
                this.$v.client.note.$model = this.original.attributes.note;
            },
            async retrieveOriginalClient() {
                this.is_loading_original = false;
                await this.$axios.get(`clients/${this.client_id}`)
                    .then(({data}) => {
                        this.is_loading_original = false;
                        this.original = data.data;
                    })
                    .catch(e => {
                        this.is_loading_original = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('client.error_retrieve')),
                            type: 'error',
                        });
                    });
            },
        },
        async mounted() {
            await this.retrieveOriginalClient();
            this.populate();
        }
    }
</script>
