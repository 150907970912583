export default {
    nav: {
        home: 'Home',
        login: 'Login',
        reset_password: 'Reset Password',
        reports: 'Reports',
        profile: 'Profile',
        logout: 'Logout',
        signup: 'Sign Up',
        bookings: 'Bookings',
        calendar: 'Calendar',
        clients: 'Clients',
        therapists: 'Therapists',
        services: 'Services',
        rooms: 'Rooms',
        users: 'Users',
        roles_permissions: 'Roles & Permissions',
    },
    profile: {
        profile: 'profile',
        user_account: 'User Account',
        personal_information: 'Personal Information',
        address_information: 'Address Information',
        change_password: 'Change Password',
        success_updated: 'Your Account has been updated',
        error_update: 'Failed to update profile',
        not_available: 'Not Available',
        availabilities: 'Availabilities',
        my_unavailable_time_slots: 'My Unavailable Time Slots',
        add_unavailable_time_slot: 'Add Unavailable Time Slot',
        delete_unavailable_time_slot: 'Delete Unavailable Time Slot',
        edit_unavailable_time_slot: 'Edit Unavailable Time Slot',
        unavailable_time_slot_success_created: 'Unavailable Time Slot has been created',
        unavailable_time_slot_success_updated: 'Unavailable Time Slot has been updated',
        unavailable_time_slot_success_deleted: 'Unavailable Time Slot has been deleted',
        unavailable_time_slot_failed_retrieve: 'Failed to retrieve Unavailable Time Slot',
        unavailable_time_slot_failed_create: 'Failed to create Unavailable Time Slot',
        unavailable_time_slot_failed_update: 'Failed to update Unavailable Time Slot',
        unavailable_time_slot_failed_delete: 'Failed to delete Unavailable Time Slot',
        unavailable_time_slot_prompt_delete: 'Are you sure you want to delete this unavailable time slot?',
    },
    audits: {
        audits: 'Audits',
        audit: 'Audit',
        user: 'User',
        event: 'Event',
        detail: 'Detail',
        date_time: 'Date & Time',
    },
    bookings: {
        therapist_id: 'Therapist ID',
        room_id: 'Room ID',
        client_id: 'Client ID',
        service_id: 'Service ID',
        user_id: 'User ID',
        booking_confirmation: 'Booking Confirmation',
        confirm_your_appointment: 'Kindly confirm your appointment',
        date: 'Date',
        time: 'Time',
        service: 'Service',
        price: 'Price',
        items_to_bring: 'Items to bring',
        client: 'Client',
        therapist: 'Therapist',
        confirm_appointment: 'Confirm Appointment',
        cancel_appointment: 'Cancel Appointment',
        appointment_confirmed: 'Appointment Confirmed',
        appointment_canceled: 'Appointment Canceled',
        no_bookings_found:'No Bookings Found',
        none: 'None',
        bookings: 'Bookings',
        booking: 'Booking',
        add_booking: 'Add Booking',
        edit_booking: 'Edit Booking',
        delete_booking: 'Delete Booking',
        search_bookings: 'Search bookings',
        id: 'ID',
        note: 'Note',
        date_time_from: 'Date & Time From',
        from: 'From',
        date_time_to: 'Date & Time To',
        to: 'To',
        date_cant_be_different: 'Date can\'t be different',
        time_cant_be_same: 'Time can\'t be the same',
        date_to_cant_be_before: 'Date & Time To can\'t be before Date & Time From',
        room: 'Room',
        status: 'Status',
        pending: 'Pending',
        canceled: 'Canceled',
        confirmed: 'Confirmed',
        actions: 'Actions',
        therapist_must_be_selected: 'A therapist must be selected',
        booking_details: 'Booking Details',
        booking_by: 'Booking By',
        old_values: 'Old values',
        new_values: 'New values',
        prompt_delete: 'Are you sure you want to delete this booking?',
        prompt_delete_unavailability: 'Are you sure you want to remove this unavailability?',
        success_created: 'Booking has been created',
        success_updated: 'Booking has been updated',
        error_create: 'Failed to create booking',
        error_update: 'Failed to update booking',
        error_delete: 'Failed to delete booking',
        error_retrieve_booking: 'Failed to retrieve booking',
        error_retrieve_bookings: 'Failed to retrieve bookings',
        error_retrieve_audits: 'Failed to retrieve audits',
        error_confirm_booking: 'Failed to confirm booking',
        error_cancel_booking: 'Failed to cancel booking',
        error_send_sms: 'Failed to send SMS',
        error_remove_unavailability: 'Failed to remove availability',
        update_service: 'Update Booking',
        delete_service: 'Delete Booking',
    },
    calendar: {
        month_view: 'Month View',
        day_view: 'Day View',
        filter_by: 'Filter by',
    },
    clients: {
        clients: 'Clients',
        add_client: 'Add Client',
        edit_client: 'Edit Client',
        search_clients: 'Search clients',
        name: 'Name',
        mobile_number: 'Mobile Number',
        phone_mobile_number: 'Phone / Mobile Number',
        phone: 'Phone',
        note: 'Note',
        actions: 'Actions',
        prompt_delete: 'Are you sure you want to delete this client?',
        success_created: 'Client has been created',
        success_updated: 'Client has been updated',
        error_create: 'Failed to create client',
        error_update: 'Failed to update client',
        error_delete: 'Failed to delete client',
        error_retrieve_client: 'Failed to retrieve client',
        error_retrieve_clients: 'Failed to retrieve clients',
        update_client: 'Update Client',
        delete_client: 'Delete Client',
    },
    therapists: {
        therapist: 'Therapist',
        therapists: 'Therapists',
        add_therapist: 'Add Therapist',
        edit_therapist: 'Edit Therapist',
        search_therapists: 'Search therapists',
        name: 'Name',
        mobile_number: 'Mobile Number',
        phone_mobile_number: 'Phone / Mobile Number',
        email: 'Email',
        phone: 'Phone',
        email_address: 'Email Address',
        password: 'Password',
        actions: 'Actions',
        prompt_delete: 'Are you sure you want to delete this therapist?',
        success_created: 'Therapist has been created',
        success_updated: 'Therapist has been updated',
        error_create: 'Failed to create therapist',
        error_update: 'Failed to update therapist',
        error_delete: 'Failed to delete therapist',
        error_retrieve_therapist: 'Failed to retrieve therapist',
        error_retrieve_therapists: 'Failed to retrieve therapists',
        update_therapist: 'Update Therapist',
        delete_therapist: 'Delete Therapist',
    },
    services: {
        services: 'services',
        add_service: 'Add Service',
        edit_service: 'Edit Service',
        search_services: 'Search services',
        name: 'Name',
        price: 'Price',
        items_to_bring: 'Items to Bring',
        actions: 'Actions',
        duration: 'Duration',
        prompt_delete: 'Are you sure you want to delete this service?',
        success_created: 'Service has been created',
        success_updated: 'Service has been updated',
        error_create: 'Failed to create service',
        error_update: 'Failed to update service',
        error_delete: 'Failed to delete service',
        error_retrieve_service: 'Failed to retrieve service',
        error_retrieve_services: 'Failed to retrieve services',
        update_service: 'Update Service',
        delete_service: 'Delete Service',
    },
    rooms: {
        add_room: 'Add Room',
        edit_room: 'Edit Room',
        delete_room: 'Delete Room',
        search_rooms: 'Search Rooms',
        room: 'Room',
        rooms: 'Rooms',
        name: 'Name',
        actions: 'Actions',
        prompt_delete: 'Are you sure you want to delete this room?',
        success_created: 'Room has been created',
        success_updated: 'Room has been updated',
        error_create: 'Failed to create room',
        error_update: 'Failed to update room',
        error_delete: 'Failed to delete room',
        error_retrieve_service: 'Failed to retrieve room',
        error_retrieve_services: 'Failed to retrieve rooms',
        update_service: 'Update Room',
        delete_service: 'Delete Room',
    },
    users: {
        users: 'users',
        add_user: 'Add User',
        edit_user: 'Edit User',
        search_users: 'Search users',
        name: 'Name',
        company: 'Company',
        mobile_number: 'Mobile Number',
        phone_mobile_number: 'Phone / Mobile Number',
        address: 'Address',
        email: 'Email',
        phone: 'Phone',
        email_address: 'Email Address',
        organisation: 'Organisation',
        company_name: 'Company Name',
        contact_person: 'Contact Person',
        vat_number: 'VAT Number',
        city: 'City',
        post_code: 'Post Code',
        password: 'Password',
        confirm_password: 'Confirm password',
        role: 'Role',
        roles: 'Roles',
        actions: 'Actions',
        prompt_delete: 'Are you sure you want to delete this user?',
        success_created: 'User has been created',
        success_updated: 'User has been updated',
        error_create: 'Failed to create user',
        error_update: 'Failed to update user',
        error_delete: 'Failed to delete user',
        error_retrieve: 'Failed to retrieve users',
        user_details: 'User Details',
        update_user: 'Update User',
        delete_user: 'Delete User',
    },
    roles: {
        roles: 'roles',
        permissions: 'Permissions',
        permission: 'Permission',
        actions: 'Actions',
        name: 'Name',
        add_role: 'Add Role',
        edit_role: 'Edit Role',
        search_roles: 'Search roles',
        prompt_delete: 'Are you sure you want to delete this role?',
        prompt_delete_subtext: '',
        error_retrieve: 'Failed to retrieve roles',
        error_create: 'Failed to create role',
        error_update: 'Failed to update role',
        error_delete: 'Failed to delete role',
        success_created: 'role has been created',
        success_updated: 'role has been updated',
        error_retrieve_fields: 'Failed to retrieve fields',
        role_details: 'Role Details',
        delete_role: 'Delete Role',
    },
    reports: {
        generate_report: 'Generate Report',
        report_type: 'Report Type',
        room_name: 'Room Name',
        organisation_name: 'Organisation Name',
        from_date: 'From Date',
        to_date: 'To Date',
        choose_a_room: 'Choose a room',
        choose_an_organisation: 'Choose an organisation',
        choose_a_from_date: 'Choose a from date',
        choose_a_to_date: 'Choose a to date',
        report_options: 'Report Options',
        preview: 'Preview',
        organisations_report: 'Organisations Report',
        rooms_report: 'Rooms Report',
        total_number_of_bookings: 'Total number of bookings',
        total_number_of_hours_booked: 'Total number of hours booked',
        time_frame: 'Time Frame',
        to_date_must_be_after_from :'The to date must be after the from date',
    },
    permissions: {
        permission: 'permission',
        permissions: 'permissions',
        error_retrieve: 'Failed to retrieve permissions',
        error_save: 'Failed to save permission',
        details_saved: 'permission details have been saved',
        saved: 'permission has been saved',
    },
    prompts: {
        are_you_sure: 'are you sure?',
        do_you_want_to_delete_this_x: 'do you want to delete this {x}?',
    },
    validation: {
        x_is_required: '{x} is required',
        x_are_required: '{x} are required',
        please_enter_x: 'please enter {x}',
        please_enter_longer_x: 'please enter a longer {x}',
        please_enter_shorter_x: 'please enter a shorter {x}',
        please_enter_valid_x: 'please enter a valid {x}',
        please_choose_x: 'please choose {x}',
        please_enter_number: 'please enter a number',
        please_enter_number_between_x: 'please enter a number between {x} and {y}',
        please_enter_url: 'please enter a URL',
        passwords_must_match: 'Passwords must match',
        password_must_be_atleast_x_characters: 'Password must be at least {x} characters',
    },
    auth: {
        unauthorized: 'Unauthorized',
        unauthenticated: 'Unauthenticated',
        forgot_password_q: 'Forgot password?',
        forgot_password: 'Forgot password',
        forgot_password_text: 'Enter your email and we\'ll send you a link to reset your password.',
        password_has_been_updated: 'Your password has been updated!',
        setup_your_account: 'Setup your account.',
        account_created: 'Account successfully created',
        email: 'Email',
        password: 'Password',
        password_is_required: 'Password is required',
        email_is_required: 'Email is required',
        reset_password: 'Reset Password',
        enter_valid_email: 'Enter a valid email',
        email_sent: 'Email successfully sent',
        return_to_login: 'Return to Login',
        enter_your_new_password: 'Enter your new password',
        retype_password: 'Re-type password',
        failed_login: 'Login Failed',
        failed_reset: 'Reset Failed',
        identity_verified: 'Your identity has been verified!',
        set_new_password: 'Set your new password!',
        failed_signup: 'Signup Failed',
    },
    localities: {
        select_locality: 'Select locality',
        error_retrieve: 'Failed to retrieve localities',
    },
    cancel: 'Cancel',
    delete: 'Delete',
    remove: 'Remove',
    start: 'Start',
    finish: 'Finish',
    add: 'Add',
    update: 'Update',
    save: 'Save',
    print: 'Print',
    send: 'Send',
    search: 'Search',
    select: 'Select',
    page_not_found: 'Page not found',
    x_per_page: '{x} per page',
    error: 'Error',
    yes: 'Yes',
    no: 'No',
    accepted: 'Accepted',
    not_accepted: 'Not Accepted',
    warning: 'Warning',
    actions: 'Actions',
    back: 'Back',
    submit: 'Submit',
    assign: 'Assign',
    okay: 'Okay',
    signup: 'Sign Up',
}
