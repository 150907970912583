<template>
    <div class="form-errors">
        <slot/>
    </div>
</template>

<script>
    export default {
        name: "FormErrors"
    }
</script>

<style lang="scss" scoped>
    .form-errors {
        @apply mt-3 mb-1;

        p {
            @apply text-negative italic text-sm py-1;
        }
    }
</style>