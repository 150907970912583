<template>
    <div class="therapists-update-modal">
        <ModalContainer :title="$t('therapists.edit_therapist')" identifier="therapists-update-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormGroupTwo>
                    <FormInputText v-model="$v.therapist.name.$model" identifier="name" :label="$t('therapists.name')"
                                   :placeholder="$t('therapists.name')" :disabled="is_saving"
                                   :has-error="$v.therapist.name.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.therapist.name.required">
                                {{$t('validation.x_is_required',{x: $t('therapists.name')})}}
                            </p>
                        </template>
                    </FormInputText>

                    <FormInputText v-model="$v.therapist.mobile_number.$model" identifier="mobile_number" :label="$t('therapists.mobile_number')"
                                   :placeholder="$t('therapists.mobile_number')" :disabled="is_saving"
                                   :has-error="$v.therapist.mobile_number.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.therapist.mobile_number.required">
                                {{$t('validation.x_is_required',{x: $t('therapists.mobile_number')})}}
                            </p>
                        </template>
                    </FormInputText>
                </FormGroupTwo>

                <FormInputText v-model="$v.therapist.email.$model" identifier="email" :label="$t('therapists.email')"
                               :placeholder="$t('therapists.email')" :disabled="is_saving"
                               :has-error="$v.therapist.email.$error" autocomplete="off">
                    <template v-slot:errors>
                        <p v-if="!$v.therapist.email.required">
                            {{$t('validation.x_is_required',{x: $t('therapists.email')})}}
                        </p>
                        <p v-else-if="!$v.therapist.email.email">
                            {{$t('auth.enter_valid_email')}}
                        </p>
                    </template>
                </FormInputText>

                <FormInputPassword v-model="$v.therapist.password.$model" identifier="password" :label="$t('therapists.password')"
                                   :placeholder="$t('therapists.password')" :disabled="is_saving" type="password"
                                   :has-error="$v.therapist.password.$error" autocomplete="new-password">
                    <template v-slot:errors>
                        <p v-if="!$v.therapist.password.required">
                            {{$t('validation.x_is_required',{x: $t('therapists.password')})}}
                        </p>
                    </template>
                </FormInputPassword>

                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{$t('save')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
    import _ from 'lodash';
    import ModalContainer from "../modal/ModalContainer";
    import Form from "../form/Form";
    import FormInputText from "../form/FormInputText";
    import FormGroupTwo from "../form/FormGroupTwo";
    import FormInputSelect from "../form/FormInputSelect";
    import {required, email} from 'vuelidate/lib/validators'
    import Button from "../Button";
    import FormInputPassword from "@/components/form/FormInputPassword";

    export default {
        name: "TherapistsUpdateModal",
        components: {FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
        props: {
            therapist_id: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                original: null,
                therapist: {
                    name: null,
                    mobile_number: null,
                    email: null,
                    password: null,
                },
                is_saving: false,
                is_loading_original: false,
            }
        },
        validations: {
            therapist: {
                name: {required},
                mobile_number: {required},
                email: {required, email},
                password: {},
            }
        },
        methods: {
            close(status) {
                this.$modal.hide('therapists-update-modal', status);
            },
            save() {
                this.$v.therapist.$touch();
                if (this.$v.therapist.$anyError || this.is_saving)
                    return;

                this.is_saving = true;

                let payload = {}

                if (this.original.attributes.name !== this.$v.therapist.name.$model)
                    payload.name = this.$v.therapist.name.$model;

                if (this.original.attributes.email !== this.$v.therapist.email.$model)
                    payload.email = this.$v.therapist.email.$model;

                if (this.original.attributes.mobile_number !== this.$v.therapist.mobile_number.$model)
                    payload.mobile_number = this.$v.therapist.mobile_number.$model;

                if (this.$v.therapist.password.$model) {
                    payload.password = this.$v.therapist.password.$model;
                }

                this.$axios.patch(`therapists/${this.therapist_id}`, payload).then(({data}) => {
                    this.$notify({
                        text: this.$t('therapists.success_updated'),
                        type: 'success',
                    });

                    this.is_saving = false;
                    this.close(true);
                }).catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('therapists.error_update')),
                        type: 'error',
                    });
                });
            },
            populate() {
                if (!this.original || !this.therapist)
                    return;

                this.$v.therapist.name.$model = this.original.attributes.name;
                this.$v.therapist.mobile_number.$model = this.original.attributes.mobile_number;
                this.$v.therapist.email.$model = this.original.attributes.email;
            },
            async retrieveOriginalTherapist() {
                this.is_loading_original = false;
                await this.$axios.get(`therapists/${this.therapist_id}`)
                    .then(({data}) => {
                        this.is_loading_original = false;
                        this.original = data.data;
                    })
                    .catch(e => {
                        this.is_loading_original = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('therapist.error_retrieve')),
                            type: 'error',
                        });
                    });
            },
        },
        async mounted() {
            await this.retrieveOriginalTherapist();
            this.populate();
        }
    }
</script>
