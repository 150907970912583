<template>
    <div class="mobile-menu-container">
        <router-link :to="{name: 'bookings-index'}" class="nav-item" @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)"
                     v-if="$store.getters.hasPermission('read bookings')">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'book-alt']"/>
            </div>
            <p>{{$t('nav.bookings')}}</p>
        </router-link>
        <router-link :to="{name: 'calendar'}" class="nav-item" @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)"
                     v-if="$store.getters.hasPermission('read bookings')">
            <div class="icon-container">
                <font-awesome-icon :icon="['far', 'calendar']"/>
            </div>
            <p>{{$t('nav.calendar')}}</p>
        </router-link>
        <router-link :to="{name: 'clients-index'}" class="nav-item" @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)"
                     v-if="$store.getters.hasPermission('read clients')">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'users']"/>
            </div>
            <p>{{$t('nav.clients')}}</p>
        </router-link>
        <router-link :to="{name: 'therapists-index'}" class="nav-item" @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)"
                     v-if="$store.getters.hasPermission('read therapists')">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'user-nurse']"/>
            </div>
            <p>{{$t('nav.therapists')}}</p>
        </router-link>
        <router-link :to="{name: 'services-index'}" class="nav-item" @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)"
                     v-if="$store.getters.hasPermission('read services')">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'flower-tulip']"/>
            </div>
            <p>{{$t('nav.services')}}</p>
        </router-link>
        <router-link :to="{name: 'rooms-index'}" class="nav-item" @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)"
                     v-if="$store.getters.hasPermission('read rooms')">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'house']"/>
            </div>
            <p>{{$t('nav.rooms')}}</p>
        </router-link>
        <router-link :to="{name: 'roles-index'}" class="nav-item" @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)"
                     v-if="$store.getters.hasPermission('read roles')">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'key']"/>
            </div>
            <p>{{$t('nav.roles_permissions')}}</p>
        </router-link>
        <router-link :to="{name: 'users-index'}" class="nav-item" @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)"
                     v-if="$store.getters.hasPermission('read users')">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'user-friends']"/>
            </div>
            <p>{{$t('nav.users')}}</p>
        </router-link>

    </div>
</template>

<script>
export default {
    name: "MobileMenu"
}
</script>

<style lang="scss" scoped>
.mobile-menu-container {
    @apply flex-1 grid grid-cols-2 content-start gap-y-12 gap-x-8 h-auto p-8 bg-white;

    @screen sm {
        @apply grid-cols-3;
    }

    @screen md {
        @apply hidden;
    }

    .nav-item {
        @apply flex flex-col items-center;

        .icon-container {
            @apply p-2 rounded-lg border-2 border-grey-light flex flex-col items-center justify-center;
            height: 40px;
            width: 40px;

            svg {
                @apply text-black w-auto;

                height: 18px;
            }
        }

        p {
            @apply text-black text-sm font-bold mt-4 text-center;
        }

        &:hover, &:focus {
            .icon-container {
                @apply border-primary;
            }
        }

        &.router-link-active {
            .icon-container {
                @apply bg-primary border-primary;

                svg {
                    @apply text-white;
                }
            }
        }
    }
}
</style>