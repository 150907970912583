<template>
    <div class="clients-create-modal">
        <ModalContainer :title="$t('clients.add_client')" identifier="clients-create-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormGroupTwo>
                    <FormInputText v-model="$v.client.name.$model" identifier="name" :label="$t('clients.name')"
                                   :placeholder="$t('clients.name')" :disabled="is_saving"
                                   :has-error="$v.client.name.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.client.name.required">
                                {{$t('validation.x_is_required',{x: $t('clients.name')})}}
                            </p>
                        </template>
                    </FormInputText>

                    <FormInputText v-model="$v.client.mobile_number.$model" identifier="mobile_number" :label="$t('clients.mobile_number')"
                                   :placeholder="$t('clients.mobile_number')" :disabled="is_saving"
                                   :has-error="$v.client.mobile_number.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.client.mobile_number.required">
                                {{$t('validation.x_is_required',{x: $t('clients.mobile_number')})}}
                            </p>
                        </template>
                    </FormInputText>
                </FormGroupTwo>

                <FormInputText v-model="$v.client.note.$model" identifier="mobile_number" :label="$t('clients.note')"
                               :placeholder="$t('clients.note')" :disabled="is_saving" :has-error="$v.client.note.$error"
                               :use-textarea="true" :large-textarea="true"/>

                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{$t('save')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
    import ModalContainer from "../modal/ModalContainer";
    import Form from "../form/Form";
    import FormInputText from "../form/FormInputText";
    import FormGroupTwo from "../form/FormGroupTwo";
    import FormInputSelect from "../form/FormInputSelect";
    import {required, email} from 'vuelidate/lib/validators'
    import Button from "../Button";
    import FormInputPassword from "@/components/form/FormInputPassword";

    export default {
        name: "ClientsCreateModal",
        components: {FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
        data() {
            return {
                client: {
                    name: null,
                    mobile_number: null,
                    note: null,
                },
                is_saving: false,
            }
        },
        validations: {
            client: {
                name: {required},
                mobile_number: {required},
                note: {},
            }
        },
        methods: {
            close(status) {
                this.$modal.hide('clients-create-modal', status);
            },
            save() {
                this.$v.client.$touch();
                if (this.$v.client.$anyError || this.is_saving)
                    return;

                this.is_saving = true;

                const payload = Object.assign({}, this.$v.client.$model);

                if(!this.client.note)
                    delete payload.note;

                this.$axios.post(`clients`, payload).then(({data}) => {
                    this.$notify({
                        text: this.$t('clients.success_created'),
                        type: 'success',
                    });

                    this.is_saving = false;
                    this.close(true);
                }).catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('clients.error_create')),
                        type: 'error',
                    });
                });
            },
        },
    }
</script>
