<template>
    <div>
        <NoLayout v-if="$route.name ==='login' || $route.name ==='reset' ||  $route.name ==='register' || $route.name === 'booking-confirmation'">
            <router-view/>
        </NoLayout>
        <AppLayout v-else>
            <router-view/>
        </AppLayout>
        <notifications position="bottom left" classes="notification-card"/>
    </div>
</template>

<script>
    import AppLayout from "./layouts/AppLayout";
    import NoLayout from "./layouts/NoLayout";

    export default {
        name: "App",
        components: {NoLayout, AppLayout}
    }
</script>
