<template>
    <loading-screen v-if="is_loading"/>
    <div v-else class="page-container">
        <div class="content" v-if="!booking_confirmed && !booking_cancelled && booking">
            <img src="../../assets/holistic-logo.png" class="logo " alt="logo"/>

            <p class="text">{{$t('bookings.confirm_your_appointment')}}</p>

            <div class="detail-item">
                <p><span>{{$t('bookings.date')}}:</span>{{$moment(booking.attributes.from).format('Do MMMM YYYY')}}</p>
                <p><span>{{$t('bookings.time')}}:</span>{{$moment(booking.attributes.from).format('HH:mm')}} - {{$moment(booking.attributes.to).format('HH:mm')}}</p>
            </div>

            <div class="detail-item">
                <p><span>{{$t('bookings.service')}}:</span>{{booking.relationships.service.attributes.name}}</p>
                <p><span>{{$t('bookings.price')}}:</span>€{{booking.relationships.service.attributes.price.toFixed(2)}}</p>
            </div>

            <div class="detail-item">
                <p><span>{{$t('bookings.items_to_bring')}}:</span>{{booking.relationships.service.attributes.items ? booking.relationships.service.attributes.items : $t('bookings.none')}}</p>
            </div>

            <div class="detail-item">
                <p><span>{{$t('bookings.client')}}:</span>{{booking.relationships.client.attributes.name}}</p>
                <p><span>{{$t('bookings.therapist')}}:</span>{{booking.relationships.therapist.attributes.name}}</p>
            </div>

            <div class="buttons-container">
                <Button className="--primary" :onclick="confirmBooking" :disabled="is_confirming || is_cancelling" :class="{spinner: is_confirming}">{{$t('bookings.confirm_appointment')}}</Button>
                <Button className="--primary --outline" :onclick="cancelBooking" :disabled="is_confirming || is_cancelling">{{$t('bookings.cancel_appointment')}}</Button>
            </div>
        </div>

        <div class="content" v-else-if="booking_confirmed">
            <img src="../../assets/holistic-logo.png" class="logo " alt="logo"/>

            <p class="text confirm-text">{{$t('bookings.appointment_confirmed')}}</p>

            <font-awesome-icon class="confirm-icon" :icon="['fal','check-circle']"/>
        </div>

        <div class="content" v-else-if="booking_cancelled">
            <img src="../../assets/holistic-logo.png" class="logo " alt="logo"/>

            <p class="text confirm-text">{{$t('bookings.appointment_canceled')}}</p>

            <font-awesome-icon class="confirm-icon" :icon="['fal','times-circle']"/>
        </div>

        <div class="footer">
            <img src="../../assets/holistic-white-logo.png" class="logo " alt="logo"/>

            <div class="contact-info">
                <p><span>Tel: </span> +356 7970 3993</p>
                <p>The Holistic Centre, Triq l-Arznell Marsaxlokk, MXK 1401</p>
            </div>

            <div class="copyright-info">
                <p>© {{year}} The Holistic Centre</p>

                <div class="engineered-by-arkafort">
                    <p>Engineered by</p>
                    <img src="../../assets/white-logo.svg" alt="Arkafort"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/components/Button";
import LoadingScreen from "@/components/LoadingScreen";
export default {
    name: "Confirmation",
    components: {LoadingScreen, Button},
    data() {
         return {
             booking: null,
             booking_confirmed: false,
             booking_cancelled: false,
             is_loading: false,
             is_confirming: false,
             is_cancelling: false,
         }
    },
    computed: {
        year() {
            let date = new Date().getFullYear();
            return date;
        }
    },
    methods: {
        getBooking() {
            this.is_loading = true;

            this.$axios.get(`bookings/${this.$route.params.id}`)
                .then(({data}) => {
                    this.booking = data.data;

                    if(this.booking.attributes.status === 'confirmed')
                        this.booking_confirmed = true;
                    else if(this.booking.attributes.status === 'canceled')
                        this.booking_cancelled = true;

                    this.is_loading = false;
                })
                .catch(e => {
                    this.is_loading = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('bookings.error_retrieve_booking')),
                        type: 'error',
                    });
                });
        },
        confirmBooking() {
            this.is_confirming = true;

            this.$axios.patch(`bookings/${this.$route.params.id}/confirm`)
                .then(({data}) => {
                    this.is_confirming = false;
                    this.booking_confirmed = true;
                })
                .catch(e => {
                    this.is_confirming = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('bookings.error_confirm_booking')),
                        type: 'error',
                    });
                });
        },
        cancelBooking() {
            this.is_cancelling = true;

            this.$axios.patch(`bookings/${this.$route.params.id}/cancel`)
                .then(({data}) => {
                    this.is_cancelling = false;
                    this.booking_cancelled = true;
                })
                .catch(e => {
                    this.is_cancelling = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('bookings.error_cancel_booking')),
                        type: 'error',
                    });
                });
        }
    },
    mounted() {
        this.getBooking();
    },
    head() {
        return {
            title: {
                inner: this.$t('bookings.booking_confirmation')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
    .page-container {
        @apply flex flex-col items-center justify-center min-h-screen w-full bg-primary;

        .content {
            @apply px-10 py-8 my-8 rounded-lg flex flex-col bg-white items-center max-w-125;
            width: 90vw;

            @screen xl {
                @apply mt-auto;
            }

            .logo {
                @apply w-auto mt-4 mb-10 object-contain h-10;
            }

            .text {
                @apply text-sm text-primary mb-8;
            }

            .detail-item {
                @apply flex flex-col bg-grey-light rounded text-grey-darker text-sm text-center mb-6 w-full py-5;

                @screen md {
                    @apply flex-row justify-evenly mb-7;
                }

                & > p {
                    &:first-of-type {
                        @apply mb-4;

                        @screen md {
                            @apply mb-0;
                        }
                    }

                    &:last-of-type {
                        @apply mb-0;
                    }

                    & > span {
                        @apply font-bold mr-1;
                    }
                }
            }

            .buttons-container {
                @apply flex flex-col w-full;

                @screen md {
                    @apply flex-row-reverse justify-center;
                }

                button {
                    @apply w-full;

                    @screen md {
                        @apply w-auto mx-2;
                    }

                    &:first-of-type {
                        @apply mb-3;

                        @screen md {
                            @apply mb-0;
                        }
                    }
                }
            }

            .confirm-text {
                @apply mt-4;
            }

            .confirm-icon {
                @apply text-primary mb-4;
                font-size: 5.65rem;
            }
        }

        .footer {
            @apply w-full flex flex-col mt-auto border-t-2 border-white justify-center;

            img {
                @apply h-8 object-contain my-8;

                @screen md {
                    @apply mb-6;
                }
            }

            .contact-info {
                @apply flex flex-col justify-center;

                @screen md {
                    @apply flex-row mb-6;
                }

                & > p {
                    @apply text-white text-sm text-center mb-8;

                    @screen md {
                        @apply my-auto;
                    }

                    &:last-of-type {
                        @apply underline;

                        @screen md {
                            @apply pl-4 ml-4 border-l-2 border-white py-1;
                        }
                    }

                    & > span {
                        @apply font-bold;
                    }
                }
            }

            .copyright-info {
                @apply flex flex-row text-xs text-white justify-center mb-4;

                & > p {
                    @apply my-auto;
                }

                @screen md {
                    @apply text-sm;
                }

                .engineered-by-arkafort {
                    @apply flex flex-row ml-2 pl-2 border-l-2 border-white py-1;

                    @screen md {
                        @apply ml-4 pl-4;
                    }

                    & > img {
                        @apply ml-2 h-4 my-auto;
                    }
                }
            }

        }
    }
</style>