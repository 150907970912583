<template>
    <aside class="sidebar-container">
        <router-link :to="{name: 'bookings-index'}" class="nav-item" v-if="$store.getters.hasPermission('read bookings')">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'book-alt']"/>
            </div>
            <p>{{$t('nav.bookings')}}</p>
        </router-link>
        <router-link :to="{name: 'calendar'}" class="nav-item" v-if="$store.getters.hasPermission('read bookings')">
            <div class="icon-container">
                <font-awesome-icon :icon="['far', 'calendar']"/>
            </div>
            <p>{{$t('nav.calendar')}}</p>
        </router-link>
        <router-link :to="{name: 'clients-index'}" class="nav-item" v-if="$store.getters.hasPermission('read clients')">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'users']"/>
            </div>
            <p>{{$t('nav.clients')}}</p>
        </router-link>
        <router-link :to="{name: 'therapists-index'}" class="nav-item" v-if="$store.getters.hasPermission('read therapists')">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'user-nurse']"/>
            </div>
            <p>{{$t('nav.therapists')}}</p>
        </router-link>
        <router-link :to="{name: 'services-index'}" class="nav-item" v-if="$store.getters.hasPermission('read services')">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'flower-tulip']"/>
            </div>
            <p>{{$t('nav.services')}}</p>
        </router-link>
        <router-link :to="{name: 'rooms-index'}" class="nav-item" v-if="$store.getters.hasPermission('read rooms')">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'house']"/>
            </div>
            <p>{{$t('nav.rooms')}}</p>
        </router-link>
        <router-link :to="{name: 'roles-index'}" class="nav-item" v-if="$store.getters.hasPermission('read roles')">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'key']"/>
            </div>
            <p>{{$t('nav.roles_permissions')}}</p>
        </router-link>
        <router-link :to="{name: 'users-index'}" class="nav-item" v-if="$store.getters.hasPermission('read users')">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'user-friends']"/>
            </div>
            <p>{{$t('nav.users')}}</p>
        </router-link>
    </aside>
</template>

<script>
    export default {
        name: "Sidebar"
    }
</script>

<style lang="scss" scoped>
    .sidebar-container {
        @apply h-full flex flex-col bg-white overflow-hidden justify-between hidden py-4;

        box-shadow: 5px 0px 10px #0000001A;
        width: 130px;
        grid-area: sidebar;

        @screen md {
            @apply flex;
        }

        @screen 3xl {
            @apply py-8;
        }

        .nav-item {
            @apply flex flex-col items-center px-4;

            .icon-container {
                @apply p-2 rounded-lg border-2 border-grey-light flex flex-col items-center justify-center;
                height: 40px;
                width: 40px;

                svg {
                    @apply text-black w-auto;
                    height: 18px;
                }
            }

            p {
                @apply text-black text-xs font-bold mt-2 text-center;

                @screen 3xl {
                    @apply text-sm mt-4;
                }
            }

            &:hover, &:focus {
                .icon-container {
                    @apply border-primary;
                }
            }

            &.router-link-active {
                .icon-container {
                    @apply bg-primary border-primary;

                    svg {
                        @apply text-white;
                    }
                }
            }
        }
    }
</style>