<template>
    <div class="page-container">
        <loading-screen v-if="is_loading || is_loading_bookings"/>
        <main v-else>
            <div class="header">
                <div class="header-inner-container">
                    <Button className="--primary --small --wider headbar-fix" :onclick="toggleAddBooking" v-if="$store.getters.hasAllPermissions(['read clients', 'read services', 'read rooms', 'read users', 'update bookings', 'destroy bookings'])">
                        {{ $t('bookings.add_booking') }}
                    </Button>

                    <Button className="--primary --small --wider headbar-fix" :onclick="toggleAddUnavailableTimeSlot">
                        {{ $t('profile.add_unavailable_time_slot') }}
                    </Button>

                    <div class="status-select-container" v-if="$store.getters.hasPermission('read users')">
                        <p>{{ $t('calendar.filter_by') }}:</p>
                        <FormInputSelect v-model="filteredTherapist" className="filter" :placeholder="$t('therapists.therapists')"
                                         :options="therapistOptions" identifier="therapist" track-by="id" display-label="name"
                                         @input="updatedTherapistFilter"/>
                    </div>

                    <div class="status-select-container">
                        <p>{{ $t('calendar.filter_by') }}:</p>
                        <FormInputSelect v-model="filteredClient" className="filter" :placeholder="$t('clients.clients')"
                                         :options="clientOptions" identifier="client" track-by="id"
                                         :display-custom-label="(row) => `${row.attributes.name} - ${row.attributes.mobile_number}`"
                                         @input="updatedClientFilter"/>
                    </div>
                </div>

                <div class="header-inner-container view-picker">
                    <button class="view-picker-button"
                            :class="[currentView === 'month' ? 'picked' : '']"
                            @click="() => toggleCurrentView('month')">
                        {{ $t('calendar.month_view') }}
                    </button>

                    <button class="view-picker-button"
                            :class="[currentView === 'day' ? 'picked' : '']"
                            @click="() => toggleCurrentView('day')">
                        {{ $t('calendar.day_view') }}
                    </button>
                </div>
            </div>
            <v-calendar v-if="currentView === 'month'" class="custom-calendar max-w-full" :masks="masks" :attributes="attributes" disable-page-swipe is-expanded>
                <template v-slot:day-content="{ day, attributes }">
                    <div class="day-container" @click="toggleDayView(day)">
                        <span class="day-wrapper">{{ day.day }}</span> <span
                        class="day-label">{{ day.ariaLabel }}</span>
                        <div class="bookings-wrapper">
                            <p v-for="attr in attributes" :key="attr.key" class="booking-container" :class="attr.customData.class" @click.stop="toggleUpdate(attr.customData)">
                                <template v-if="attr.customData.type === 'booking'">
                                    <span style="display: block;">{{attr.customData.timeSlot }} | ID: {{ attr.customData.bookingId }}</span>
                                    <span style="display: block;">{{attr.customData.client }} - {{ attr.customData.therapist }}</span>
                                    <span style="display: block; text-transform: capitalize">{{attr.customData.room}} - Status: {{attr.customData.status}}</span>
                                </template>
                                <template v-if="attr.customData.type === 'unavailability'">
                                    <span style="display: block;">{{attr.customData.timeSlot }}</span>
                                    <span style="display: block;">{{attr.customData.therapist }}</span>
                                    <span style="display: block; text-transform: capitalize">{{$t('profile.not_available')}}</span>
                                </template>
                            </p>
                        </div>
                    </div>
                </template>
            </v-calendar>
            <div v-if="currentView === 'day'" class="day-view-container">
                <div class="day-view-calendar">
                    <div class="day-view-calender-header">
                        <div class="day-view-calender-header-container">
                            <button class="day-toggle-button"  @click="toggleDayViewDate('previous')">
                                <font-awesome-icon :icon="['fal', 'chevron-left']"/>
                            </button>
                            <h1>{{ $moment(dayViewDate).format('dddd DD MMMM YYYY') }}</h1>
                            <button class="day-toggle-button" @click="toggleDayViewDate('next')">
                                <font-awesome-icon :icon="['fal', 'chevron-right']"/>
                            </button>
                        </div>
                    </div>
                    <p v-if="!filteredTherapist" class="select-therapist-text">{{$t('bookings.therapist_must_be_selected')}}</p>
                    <div v-else="filteredTherapist" class="day-view-calendar-content">
                        <div class="time-row" v-for="slot in slots">
                            <p class="slot">{{slot.time}}</p>
                            <div class="booking-container">
                                <p v-for="booking in slot.bookings" class="booking" :class="booking.customData.class" @click.stop="toggleUpdate(booking.customData)"
                                   :style="{height: `${3 * booking.customData.timeSlots}rem`, 'padding-top': `${booking.customData.timeSlots <= 2 ? '0.5rem' : '1rem'}`, 'padding-bottom': `${booking.customData.timeSlots < 2 ? '0.5rem' : '1rem'}`, 'flex-direction': `${booking.customData.timeSlots < 2 ? 'row' : 'column'}`, 'justify-content': `${booking.customData.timeSlots <= 2 ? 'space-between' : 'start'}`}">
                                    <template v-if="booking.customData.type === 'booking'">
                                        <span>{{booking.customData.timeSlot }} | ID: {{ booking.customData.bookingId }}</span>
                                        <span>{{booking.customData.client }} - {{ booking.customData.therapist }}</span>
                                        <span style="text-transform: capitalize">{{booking.customData.room}} | Status: {{booking.customData.status}}</span>
                                    </template>
                                    <template v-if="booking.customData.type === 'unavailability'">
                                        <span>{{booking.customData.timeSlot }}</span>
                                        <span>{{booking.customData.therapist }}</span>
                                        <span style="text-transform: capitalize">{{$t('profile.not_available')}}</span>
                                    </template>
                                </p>
                            </div>
                        </div>
                    </div>
<!--                    <div class="day-view-calendar-body">-->
<!--                        <template v-if="dayAttributes.length > 0">-->
<!--                            <div v-for="attr in dayAttributes" :key="attr.key" class="booking-container">-->
<!--                                <p class="booking" @click.stop="toggleUpdate(attr.customData)" :class="attr.customData.class">-->
<!--                                    <template v-if="attr.customData.type === 'booking'">-->
<!--                                        <span>{{attr.customData.timeSlot }} | ID: {{ attr.customData.bookingId }}</span>-->
<!--                                        <span>{{attr.customData.client }} - {{ attr.customData.therapist }}</span>-->
<!--                                        <span style="text-transform: capitalize">{{attr.customData.room}} - Status: {{attr.customData.status}}</span>-->
<!--                                    </template>-->
<!--                                    <template v-if="attr.customData.type === 'unavailability'">-->
<!--                                        <span>{{attr.customData.timeSlot }}</span>-->
<!--                                        <span>{{attr.customData.therapist }}</span>-->
<!--                                        <span style="text-transform: capitalize">{{$t('profile.not_available')}}</span>-->
<!--                                    </template>-->
<!--                                </p>-->
<!--                            </div>-->
<!--                        </template>-->
<!--                        <template v-else>-->
<!--                            <div class="no-bookings-found-container">-->

<!--                            <h1>{{$t('bookings.no_bookings_found')}}</h1>-->
<!--                            </div>-->
<!--                        </template>-->
<!--                    </div>-->
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import Headbar from "../../components/headbar/Headbar";
import Search from "../../components/Search";
import Button from "../../components/Button";
import Select from "../../components/form/Select";
import ConfirmModal from "../../components/modal/ConfirmModal";
import LoadingScreen from "../../components/LoadingScreen";
import BookingsUpdateModal from "../../components/bookings/BookingsUpdateModal";
import FormInputSelect from "../../components/form/FormInputSelect";
import BookingsCreateModal from "../../components/bookings/BookingsCreateModal";
import UnavailableTimeSlotCreateModal from "../../components/therapists/UnavailableTimeSlotCreateModal";
import UnavailableTimeSlotUpdateModal from "../../components/therapists/UnavailableTimeSlotUpdateModal";

export default {
    name: "calendar-index-page",
    components: {ConfirmModal, Select, Button, Search, Headbar, LoadingScreen, FormInputSelect},
    data: function () {
        const month = new Date().getMonth();
        const year = new Date().getFullYear();
        return {
            masks: {
                weekdays: 'WWW',
            },
            attributes: [],
            dayAttributes: [],
            bookings: [],
            clientOptions: [],
            therapistOptions: [],
            unavailabilities: [],
            slots: [],
            dayViewDate: null,
            filteredClient: null,
            filteredTherapist: null,
            is_loading_bookings: false,
            is_loading_therapists: false,
            is_loading_clients: false,
            is_loading_unavailabilities: false,
            is_loading: true,
            totalRecords: null,
            serverParams: {sorting: []},
            searchTerm: null,
            currentView: 'month',
            is_deleting: false,
            headbarExpanded: false
        }
    },
    methods: {
        async toggleUpdate(booking) {
            if(!this.$store.getters.hasPermission('read users')) return;

            if (booking.type === 'booking') {
                this.$modal.show(
                    BookingsUpdateModal,
                    {
                        booking_id: booking.bookingId,
                    },
                    {
                        name: 'bookings-update-modal',
                        adaptive: true,
                        resizable: true,
                        height: 'auto',
                        scrollable: true,
                        classes: 'modal',
                    }, {
                        'before-close': async (e) => {
                            if (e.params === true) {
                                await this.retrieveTherapists();
                                await this.retrieveBookings();
                                await this.generateCalendarAttributes();
                                await this.getDayCalendarAttributes();
                            }
                        }
                    }
                );
            }
            else if (booking.type === 'unavailability'){
                this.$modal.show(
                    UnavailableTimeSlotUpdateModal,
                    {
                        unavailability_id: booking.unavailabilityId,
                        from_profile: false,
                    },
                    {
                        name: 'unavailable-time-slot-update-modal',
                        adaptive: true,
                        resizable: true,
                        height: 'auto',
                        scrollable: true,
                        classes: 'modal',
                    }, {
                        'before-close': async (e) => {
                            if (e.params === true) {
                                await this.retrieveTherapists();
                                await this.retrieveBookings();
                                await this.retrieveUnavailabilities();
                                await this.generateCalendarAttributes();
                                await this.getDayCalendarAttributes();
                            }
                        }
                    }
                );
            }
        },
        async retrieveBookings() {
            this.is_loading_bookings = true;

            const encodedSorting = this.serverParams.sorting.map(f => btoa(JSON.stringify(f)));

            await this.$axios.get('bookings/list', {params: {...this.serverParams, sorting: encodedSorting}})
                .then(({data}) => {
                    this.bookings = data.data;
                    this.is_loading_bookings = false;
                })
                .catch(e => {
                    this.is_loading_bookings = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('bookings.error_retrieve_bookings')),
                        type: 'error',
                    });
                });
        },
        async generateCalendarAttributes() {
            this.attributes = [];
            this.is_loading = true;

            this.bookings.forEach((booking) => {
                let fromTime = booking.attributes.from.split(" ")[1].substring(0, booking.attributes.from.split(" ")[1].length - 3);
                let toTime = booking.attributes.to.split(" ")[1].substring(0, booking.attributes.to.split(" ")[1].length - 3);

                let time_slots = this.$moment(toTime, 'HH:mm').diff(this.$moment(fromTime, 'HH:mm'), 'minutes') / 15

                let bookingDate = this.$moment(booking.attributes.from, 'YYYY-MM-DD HH:mm:ss')
                let dayTime = bookingDate.date();
                let monthTime = bookingDate.month();
                let yearTime = bookingDate.year();

                let customCSS = '';

                if (booking.attributes.status === 'pending')
                    customCSS = 'bg-pending text-white'
                if (booking.attributes.status === 'confirmed')
                    customCSS = 'bg-accepted text-white'
                if (booking.attributes.status === 'canceled')
                    customCSS = 'bg-rejected text-white'

                this.attributes.push({
                    key: 0,
                    customData: {
                        type: 'booking',
                        bookingId: booking.id,
                        clientId: booking.relationships.client.id,
                        therapistId: booking.relationships.therapist.id,
                        timeSlot: `${fromTime} - ${toTime}`,
                        therapist: booking.relationships.therapist.attributes.name,
                        room: booking.relationships.room.attributes.name,
                        client: booking.relationships.client.attributes.name,
                        class: customCSS,
                        status: booking.attributes.status,
                        timeSlots: time_slots,
                    },
                    dates: new Date(yearTime, monthTime, dayTime, Number(fromTime.substring(0, 2))),
                })
            })

            this.unavailabilities.forEach((unavailability) => {
                let fromTime = unavailability.attributes.from.split(" ")[1].substring(0, unavailability.attributes.from.split(" ")[1].length - 3);
                let toTime = unavailability.attributes.to.split(" ")[1].substring(0, unavailability.attributes.to.split(" ")[1].length - 3);

                let time_slots = this.$moment(toTime, 'HH:mm').diff(this.$moment(fromTime, 'HH:mm'), 'minutes') / 15

                let bookingDate = this.$moment(unavailability.attributes.from, 'YYYY-MM-DD HH:mm:ss')
                let dayTime = bookingDate.date();
                let monthTime = bookingDate.month();
                let yearTime = bookingDate.year();

                let customCSS = 'bg-primary text-white';

                let tempTherapist;
                let tempTherapistId;

                if (unavailability.relationships.therapist){
                    tempTherapist = unavailability.relationships.therapist.data.attributes.name;
                    tempTherapistId = unavailability.relationships.therapist.data.id;
                }

                this.attributes.push({
                    key: 0,
                    customData: {
                        unavailabilityId: unavailability.id,
                        therapistId: tempTherapistId,
                        type: 'unavailability',
                        timeSlot: `${fromTime} - ${toTime}`,
                        therapist: tempTherapist,
                        class: customCSS,
                        status: this.$t('profile.not_available'),
                        timeSlots: time_slots
                    },
                    dates: new Date(yearTime, monthTime, dayTime, Number(fromTime.substring(0, 2))),
                })

            })

            this.attributes.sort(function (a, b) {
                return new Date(a.dates) - new Date(b.dates);
            });

            this.attributes.forEach((element, index) => {
                element.key = index;
            })

            if (this.filteredClient)
                this.attributes = this.attributes.filter(x => x.customData.clientId === this.filteredClient.id);

            if (this.filteredTherapist) {
                this.attributes = this.attributes.filter(x => x.customData.therapistId === this.filteredTherapist.id);
            }

            this.is_loading = false;
        },
        generateTimeSlots() {
            this.slots = [];
            let quarterHours = ["00", "15", "30", "45"];

            for(let i = 5; i <= 24; i++) {
                for (let j = 0; j < 4; j++) {
                    if (i < 10) {
                        this.slots.push({time: "0" + i + ":" + quarterHours[j], bookings: []});
                    } else if (i >= 10 && i < 24) {
                        this.slots.push({time: i + ":" + quarterHours[j], bookings: []});
                    }
                }
            }
        },
        getDayCalendarAttributes() {
            this.generateTimeSlots();

            this.attributes.forEach(attribute => {
                if (this.$moment(attribute.dates).isSame(this.$moment(this.dayViewDate), 'day')) {
                    this.slots.find(slot => slot.time === attribute.customData.timeSlot.slice(0, 5)).bookings.push(attribute);
                }
            });
        },
        updatedClientFilter(e) {
            this.filteredClient = e;
            this.generateCalendarAttributes();
            this.getDayCalendarAttributes();
        },
        updatedTherapistFilter(e) {
            this.filteredTherapist = e;
            this.generateCalendarAttributes();
            this.getDayCalendarAttributes();
        },
        toggleAddBooking() {
            this.$modal.show(
                BookingsCreateModal, {},
                {
                    name: 'bookings-create-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': async (e) => {
                        if (e.params === true)
                            await this.retrieveTherapists();
                            await this.retrieveBookings();
                            await this.generateCalendarAttributes();
                            await this.getDayCalendarAttributes();
                    }
                }
            );
        },
        toggleAddUnavailableTimeSlot() {
            this.$modal.show(
                UnavailableTimeSlotCreateModal, {
                    from_profile: false
                },
                {
                    name: 'unavailable-time-slot-create-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': async (e) => {
                        if (e.params === true) {
                            await this.retrieveTherapists();
                            await this.retrieveBookings();
                            await this.retrieveUnavailabilities();
                            await this.generateCalendarAttributes();
                            await this.getDayCalendarAttributes();
                        }
                    }
                }
            );
        },
        toggleCurrentView(newView) {
            if (newView === 'month')
                this.dayViewDate = this.$moment()

            if (newView === 'day')
                this.getDayCalendarAttributes();

            this.currentView = newView
        },
        toggleDayView(day) {
            this.currentView = 'day'
            this.dayViewDate = this.$moment(day.date)
            this.getDayCalendarAttributes();
        },
        toggleDayViewDate(e) {
            if (e === 'next')
                this.dayViewDate = this.$moment(this.dayViewDate).add(1, 'days')

            else if (e === 'previous')
                this.dayViewDate = this.$moment(this.dayViewDate).subtract(1, 'days')

            this.getDayCalendarAttributes();
        },
        async retrieveClients() {
            this.is_loading_clients = true;

            await this.$axios.get('clients/list')
                .then(({data}) => {
                    this.clientOptions = data.data;
                    this.is_loading_clients = false;
                })
                .catch(e => {
                    this.is_loading_clients = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('clients.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async retrieveTherapists() {
            if(!this.$store.getters.hasPermission('read users')) return;

            this.is_loading_therapists = true;
            
            await this.$axios.get('therapists/list')
                .then(({data}) => {
                    this.therapistOptions = data.data.map(x => ({
                        id: x.id,
                        name: x.attributes.name,
                        column: x.attributes.column
                    }))
                    this.is_loading_therapists = false;
                })
                .catch(e => {
                    this.is_loading_therapists = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('therapists.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async retrieveUnavailabilities() {
            this.is_loading_unavailabilities = false;
            await this.$axios.get('unavailabilities/list')
                .then(({data}) => {
                    this.unavailabilities = data.data;
                    this.is_loading_unavailabilities = false;
                })
                .catch(e => {
                    this.is_loading_unavailabilities = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('profile.unavailable_time_slot_failed_retrieve')),
                        type: 'error',
                    });
                });
        },
    },
    async mounted() {
        await this.retrieveClients();
        await this.retrieveTherapists();
        await this.retrieveBookings();
        await this.retrieveUnavailabilities();
        await this.generateCalendarAttributes();
        this.dayViewDate = this.$moment()
    },
    head() {
        return {
            title: {
                inner: this.$t('nav.calendar')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    scrollbar-width: thin !important;

    ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    ::-webkit-scrollbar-track {
        @apply bg-grey-light rounded-lg;
    }

    ::-webkit-scrollbar-thumb {
        @apply bg-grey-darker rounded-lg;
    }

    ::-webkit-scrollbar-thumb:hover {
        @apply bg-grey-dark;
    }

    .header {
        @apply flex flex-col items-center mb-4 gap-y-4 flex-wrap;

        @screen lg {
            @apply flex flex-row items-center justify-between;
        }

        .header-inner-container {
            @apply flex flex-col items-center gap-y-4;

            button {
                @apply w-64;
            }

            @screen lg {
                @apply flex flex-row items-center gap-x-4;
            }

            &.view-picker {
                @apply flex flex-row items-center gap-x-4 w-64;

                .view-picker-button {
                    @apply text-primary font-bold text-xs px-5 py-3 rounded-md;

                    &.picked {
                        @apply bg-primary text-white;
                    }
                }
            }
        }
    }

    .status-select-container {
        @apply flex flex-row gap-x-4 items-center w-full;

        @screen lg {
            @apply w-auto;
        }

        & > p {
            @apply text-primary font-bold text-xs hidden;

            @screen xl {
                @apply block;
            }
        }

        .input-group {
            @apply w-full mb-0 ml-auto;

            @screen lg {
                @apply w-36 ml-0;
            }
        }
    }

    .day-container {
        @apply flex flex-col h-full z-10 overflow-hidden cursor-pointer transition-all;

        &:hover {
            @apply bg-grey-lighter;
        }

        .day-label {
            @screen sm {
                @apply hidden;
            }
        }

        .day-wrapper {
            @apply hidden;

            @screen sm {
                @apply block;
            }
        }

        .bookings-wrapper {
            @apply flex-grow overflow-y-auto overflow-x-auto pr-1;

            .booking-container {
                @apply text-xs leading-tight rounded-md p-1 mt-2 mb-1 cursor-pointer;
            }
        }

    }

    .day-view-container {
        @apply flex flex-row w-full;

        @screen lg {
            height: 78vh;
        }

        .day-view-calendar {
            @apply mx-auto border-2 border-grey-light rounded-lg w-full pt-4 bg-white overflow-scroll;

            @screen lg {
                @apply w-3/4;
                min-width: 48rem;
            }

            .day-view-calender-header {
                @apply flex flex-row items-center border-b-2 border-grey-light pb-2 w-full;

                .day-view-calender-header-container {
                    @apply mx-auto flex flex-row items-center gap-x-8;

                    h1 {
                        @apply font-bold text-xl text-center;

                        @screen lg {
                            @apply text-3xl;
                            width: 32rem;
                        }
                    }

                    .day-toggle-button {
                        @apply p-5 font-bold text-xl;

                        @screen lg {
                            @apply text-3xl;
                        }
                    }
                }
            }

            .select-therapist-text {
                @apply my-8 w-full text-center;
            }

            .day-view-calendar-content {
                @apply flex flex-col;

                & > .time-row {
                    @apply pl-4 border-b border-grey-light flex flex-row h-12 relative;

                    & > .slot {
                        @apply text-sm text-primary font-bold mr-4 my-auto;
                    }

                    .booking-container {
                        @apply w-full;

                        .booking {
                            @apply flex flex-col gap-y-4 leading-tight rounded-md p-2 cursor-pointer w-full absolute;
                            max-width: 18rem;
                            z-index: 2;
                            font-size: 10px;

                            @screen xxs {
                                max-width: 20rem;
                            }

                            @screen xs {
                                max-width: 24rem;
                            }

                            @screen sm {
                                @apply max-w-md mx-auto p-4 text-xs;
                                left: 0;
                                right: 0;
                            }

                        }
                    }
                }
            }

        }
    }
}
</style>
