<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <Button className="--primary --small" v-if="$store.getters.hasAllPermissions(['read clients', 'read services', 'read rooms', 'read users', 'update bookings', 'destroy bookings'])"
                        :onclick="toggleCreate">
                    {{ $t('bookings.add_booking') }}
                </Button>
            </template>
            <template v-slot:right>
                <div class="search-desktop" v-if="$store.getters.hasPermission('read users')">
                    <div class="filter-wrapper">
                        <div class="filter-container date-container">
                            <p>{{$t('calendar.filter_by')}}</p>
                            <FormInputDateTime v-model="filter_date" identifier="from" :placeholder="$t('bookings.date')"
                                               className="filter" :only-date="true" :minute-interval="15"
                                               formatted="DD/MM/YYYY" @input="onFilterUpdated"/>
                        </div>
                        <div class="filter-container">
                            <p>{{$t('calendar.filter_by')}}</p>

                            <FormInputSelect v-model="filter_client" className="filter" :placeholder="$t('clients.clients')"
                                             :options="clientOptions" identifier="client" track-by="id"
                                             :display-custom-label="(row) => `${row.attributes.name} - ${row.attributes.mobile_number}`"
                                             @input="onFilterUpdated"/>
                        </div>
                        <div class="filter-container">
                            <p>{{$t('calendar.filter_by')}}</p>

                            <FormInputSelect v-model="filter_therapist" className="filter" :placeholder="$t('therapists.therapists')"
                                             :options="therapistOptions" identifier="therapist" track-by="id"
                                             display-label="name" @input="onFilterUpdated"/>
                        </div>
                    </div>
                </div>
                <button class="btn-search-mobile btn-icon-only"
                        @click="headbarExpanded = headbarExpanded === 'search' ? null : 'search'">
                    <font-awesome-icon v-if="headbarExpanded === 'search'" :icon="['far', 'times']"/>
                    <font-awesome-icon v-else :icon="['far', 'search']"/>
                </button>
            </template>
            <template v-slot:expanded>
                <div class="headbar-expanded-container">
                    <div class="filter-container date-container" v-if="headbarExpanded === 'search'">
                        <p>{{$t('calendar.filter_by')}}</p>
                        <FormInputDateTime v-model="filter_date" identifier="from" :placeholder="$t('bookings.date')"
                                           className="filter" :minute-interval="15" @input="onFilterUpdated"/>
                    </div>
                    <div class="filter-container" v-if="headbarExpanded === 'search'">
                        <p>{{$t('calendar.filter_by')}}</p>
                        <FormInputSelect v-model="filter_client" className="filter"  :placeholder="$t('clients.clients')"
                                         :options="clientOptions" identifier="client" track-by="id"
                                         :display-custom-label="(row) => `${row.attributes.name} - ${row.attributes.mobile_number}`"
                                         @input="onFilterUpdated"/>
                    </div>
                    <div class="filter-container" v-if="headbarExpanded === 'search'">
                        <p>{{$t('calendar.filter_by')}}</p>
                        <FormInputSelect v-model="filter_therapist" className="filter"  :placeholder="$t('therapists.therapists')"
                                         :options="therapistOptions" identifier="therapist" track-by="id"
                                         display-label="name" @input="onFilterUpdated"/>
                    </div>
                </div>
            </template>
        </Headbar>
        <main>
            <vue-good-table
                mode="remote"
                styleClass="vgt-table vgt-custom"
                :columns="columns"
                :rows="bookings"
                :isLoading.sync="is_loading_bookings"
                :search-options="{
                    enabled: false,
                }"
                :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    dropdownAllowAll: false,
                    perPage: 15,
                    perPageDropdownEnabled: false,
                    rowsPerPageLabel: $t('x_per_page', {x: $t('bookings.bookings')}),
                }"
                :sort-options="{
                  enabled: true,
                  multipleColumns: true,
                }"
                :totalRows="totalRecords"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange">
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'attributes.from'">
                        <p style="text-transform: capitalize;">
                            {{ $moment(props.row.attributes.from).format('DD/MM/YYYY - HH:mm') }}</p>
                    </div>
                    <div v-else-if="props.column.field === 'attributes.to'">
                        <p style="text-transform: capitalize;">
                            {{ $moment(props.row.attributes.to).format('DD/MM/YYYY - HH:mm') }}</p>
                    </div>
                    <div v-else-if="props.column.field === 'attributes.status'">
                        <p v-if="props.row.attributes.status === 'pending'" class="status pending">
                            {{ props.row.attributes.status }}</p>
                        <p v-if="props.row.attributes.status === 'rejected'" class="status rejected">
                            {{ props.row.attributes.status }}</p>
                        <p v-if="props.row.attributes.status === 'canceled'" class="status cancelled">
                            {{ props.row.attributes.status }}</p>
                        <p v-if="props.row.attributes.status === 'confirmed'" class="status confirmed">
                            {{ props.row.attributes.status }}</p>
                    </div>
                    <div v-else-if="props.column.field === 'after'" class="td-after">
                        <router-link :to="{name: 'bookings-update', params: {id: props.row.id}}">
                            <Button className="--secondary --outline --mini --big-text">
                                <font-awesome-icon :icon="['fal', 'pencil']"/>
                            </Button>
                        </router-link>
                        <Button
                            className="--secondary --outline --mini --big-text"
                            :onclick="()=>toggleDelete(props.row)">
                            <font-awesome-icon :icon="['fal', 'trash']"/>
                        </Button>
                    </div>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>
        </main>
    </div>
</template>

<script>
import Headbar from "../../components/headbar/Headbar";
import Search from "../../components/Search";
import Button from "../../components/Button";
import Select from "../../components/form/Select";
import ConfirmModal from "../../components/modal/ConfirmModal";
import BookingsCreateModal from "../../components/bookings/BookingsCreateModal";
import FormInputSelect from "../../components/form/FormInputSelect";
import FormInputDateTime from "../../components/form/FormInputDateTime";

export default {
    name: "bookings-index-page",
    components: {ConfirmModal, Select, Button, Search, Headbar, FormInputSelect, FormInputDateTime},
    data: function () {
        const columns = [
            {
                label: this.$t('bookings.id'),
                field: 'id',
                sortable: false,
            },
            {
                label: this.$t('bookings.client'),
                field: 'relationships.client.attributes.name',
                sortable: false,
            },
            {
                label: this.$t('bookings.therapist'),
                field: 'relationships.therapist.attributes.name',
                sortable: false,
            },
            {
                label: this.$t('bookings.date_time_from'),
                field: 'attributes.from',
                sortable: false,
            },
            {
                label: this.$t('bookings.date_time_to'),
                field: 'attributes.to',
                sortable: false,
            },
            {
                label: this.$t('bookings.room'),
                field: 'relationships.room.attributes.name',
                sortable: false,
            },
            {
                label: this.$t('bookings.status'),
                field: 'attributes.status',
                sortable: false,
            },
        ];

        if (this.$store.getters.hasAllPermissions(['read clients', 'read services', 'read rooms', 'read users', 'update bookings', 'destroy bookings']))
            columns.push({
                label: this.$t('bookings.actions'),
                field: 'after',
                tdClass: 'td-after',
                sortable: false
            });

        return {
            columns: columns,
            bookings: [],
            clientOptions: [],
            therapistOptions: [],
            is_loading_clients: false,
            is_loading_therapists: false,
            is_loading_bookings: false,
            totalRecords: null,
            serverParams: {
                sort_by: 'from',
                sort_order: 'asc',
                filters: []
            },
            searchTerm: null,
            filter_client: null,
            filter_therapist: null,
            filter_date: null,
            is_deleting: false,
            headbarExpanded: false
        }
    },
    methods: {
        toggleDelete(booking) {
            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('bookings.delete_booking'),
                    message: this.$t('bookings.prompt_delete'),
                    confirmText: this.$t('delete'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.is_deleting = true;
                            this.$axios.delete(`bookings/${booking.id}`)
                                .then(({}) => {
                                    this.is_deleting = false;
                                    this.retrieveBookings();
                                })
                                .catch(e => {
                                    this.is_deleting = false;

                                    this.$notify({
                                        title: this.$t('error'),
                                        text: this.$larerror(e.response.data, this.$t('bookings.error_delete')),
                                        type: 'error',
                                    });
                                });
                        }
                    }
                }
            );
        },
        toggleCreate() {
            this.$modal.show(
                BookingsCreateModal, {},
                {
                    name: 'bookings-create-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.retrieveBookings();
                    }
                }
            );
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.retrieveBookings();
        },
        onSortChange(params) {
            const sorts = [];

            if (!params || !params.length) {
                this.updateParams({sorting: []});
                return this.retrieveBookings();
            }

            params.forEach(p => {
                if (!p.type || p.type === 'none')
                    return;

                let sort_by = null;
                let sort_order = p.type;
                if (p.field === 'attributes.name')
                    sort_by = 'name';
                else if (p.field === 'attributes.email')
                    sort_by = 'email';
                else if (p.field === 'role')
                    sort_by = 'role';
                else
                    sort_by = p.field.split('.')[1];

                sorts.push({sort_order, sort_by})
            });

            this.updateParams({sorting: sorts});
            this.retrieveBookings();
        },
        removeParam(param) {
            this.$delete(this.serverParams, param);
        },
        search(searchTerm) {
            this.searchTerm = searchTerm;

            if (searchTerm && searchTerm.length)
                this.updateParams({term: searchTerm});
            else this.removeParam('term');

            this.retrieveBookings();
        },
        onFilterUpdated() {
            const filters = [];

            if (this.filter_client)
                filters.push({filter_by: 'client_id', filter_value: this.filter_client.id});

            if (this.filter_therapist)
                filters.push({filter_by: 'therapist_id', filter_value: this.filter_therapist.id});

            if (this.filter_date)
                filters.push(
                    {filter_by: 'from', filter_operator: '>=', filter_value: this.$moment(this.filter_date).startOf('day').format()},
                    {filter_by: 'to', filter_operator: '<=', filter_value: this.$moment(this.filter_date).endOf('day').format()},
                );
            else {
                filters.push({filter_by: 'to', filter_operator: '>=', filter_value: this.$moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')})
            }

            if (filters.length)
                this.updateParams({
                    filters: filters
                });
            else this.removeParam('filters');

            this.retrieveBookings();
        },
        async retrieveBookings() {
            this.is_loading_bookings = true;

            await this.$axios.get('bookings', {params: {...this.serverParams}})
                .then(({data}) => {
                    this.bookings = data.data;
                    this.totalRecords = data.meta.total;
                    this.is_loading_bookings = false;
                })
                .catch(e => {
                    this.is_loading_bookings = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('bookings.error_retrieve_bookings')),
                        type: 'error',
                    });
                });
        },
        async retrieveClients() {
            this.is_loading_clients = true;
            
            await this.$axios.get('clients/list')
                .then(({data}) => {
                    this.clientOptions = data.data;
                    this.is_loading_clients = false;
                })
                .catch(e => {
                    this.is_loading_clients = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('clients.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async retrieveTherapists() {
            if(!this.$store.getters.hasPermission('read users')) return;

            this.is_loading_therapists = true;
            
            await this.$axios.get('therapists/list')
                .then(({data}) => {
                    this.therapistOptions = data.data.map(x => ({
                        id: x.id,
                        name: x.attributes.name,
                        column: x.attributes.column
                    }))
                    this.is_loading_therapists = false;
                })
                .catch(e => {
                    this.is_loading_therapists = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('therapists.error_retrieve')),
                        type: 'error',
                    });
                });
        },
    },
    async mounted() {
        this.serverParams.filters.push({filter_by: 'to', filter_operator: '>=', filter_value: this.$moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')})
        await this.retrieveClients();
        await this.retrieveTherapists();
        await this.retrieveBookings();
    },
    head() {
        return {
            title: {
                inner: this.$t('nav.bookings')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container {

    .filter-wrapper {
        @apply flex flex-row gap-x-8;

        .filter-container {
            @apply flex flex-row gap-x-4 items-center w-full;

            @screen lg {
                @apply w-auto;
            }

            & > p {
                @apply text-primary font-bold text-xs;
            }

            .input-group {
                @apply w-36 mb-0 ml-auto;

                @screen lg {
                    @apply ml-0;
                }
            }

            &.date-container {
                & > .input-group {
                    @apply min-w-50;
                }
            }
        }
    }

    .headbar-expanded-container {
        @apply mt-4 flex flex-col gap-y-4 items-center;

        @screen lg {
            @apply hidden;
        }

        .filter-container {
            @apply flex flex-row gap-x-4 items-center mx-auto;

            @screen lg {
                @apply w-auto;
            }

            & > p {
                @apply text-primary font-bold text-xs;
            }

            .input-group {
                @apply w-36 mb-0;

                @screen lg {
                    @apply ml-0;
                }
            }

            &.date-container {
                & > .input-group {
                    @apply min-w-50;
                }
            }
        }
    }

    .select-dropdown {
        @apply min-w-40;
    }

    .btn-search-mobile, .btn-filter-mobile {
        @apply block text-primary w-6;

        @screen lg {
            @apply hidden;
        }

        &:active, &:focus {
            @apply outline-none;
        }
    }

    .search-desktop, .filter-desktop {
        @apply hidden;

        @screen lg {
            @apply block ml-4;
        }
    }

    .search-container {
        @apply mr-0;
    }

    .filter-mobile {
        @apply max-w-xs;
    }

    .td-after {
        @apply flex flex-row;

        & > * {
            @apply mr-3;

            &:last-child {
                @apply mr-0;
            }
        }
    }

    .status {
        @apply capitalize;

        &.confirmed {
            @apply text-accepted;
        }

        &.pending {
            @apply text-pending;
        }

        &.cancelled {
            @apply text-rejected;
        }
    }
}
</style>
