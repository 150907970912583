<template>
    <div class="services-update-modal">
        <ModalContainer :title="$t('services.edit_service')" identifier="services-update-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormGroupTwo>
                    <FormInputText v-model="$v.service.name.$model" :label="$t('services.name')"
                                   :placeholder="$t('services.name')" :disabled="is_saving"
                                   :has-error="$v.service.name.$error" autocomplete="off">
                        <template v-slot:errors>
                            <p v-if="!$v.service.name.required">
                                {{ $t('validation.x_is_required', {x: $t('services.name')}) }}
                            </p>
                        </template>
                    </FormInputText>

                    <FormInputText v-model="$v.service.price.$model" identifier="name" :label="$t('services.price')"
                                   :placeholder="$t('services.price')" :disabled="is_saving"
                                   :has-error="$v.service.price.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.service.price.required">
                                {{ $t('validation.x_is_required', {x: $t('services.price')}) }}
                            </p>
                        </template>
                    </FormInputText>
                </FormGroupTwo>

                <FormInputDateTime v-model="$v.service.duration.$model" identifier="duration" :label="$t('services.duration')"
                                   :placeholder="$t('services.duration')" :only-time="true" format="HH:mm" formatted="HH:mm"
                                   :disabled="is_saving" :minute-interval="5"
                                   :hasError="$v.service.duration.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.service.duration.required">
                            {{ $t('validation.x_is_required', {x: $t('services.duration')}) }}
                        </p>
                    </template>
                </FormInputDateTime>

                <FormInputText v-model="$v.service.items.$model" identifier="name" :label="$t('services.items_to_bring')"
                               :placeholder="$t('services.items_to_bring')" :disabled="is_saving"/>

                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{ $t('save') }}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import _ from 'lodash';
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import FormInputDateTime from "../form/FormInputDateTime";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";

export default {
    name: "ServicesUpdateModal",
    components: {Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer, FormInputDateTime},
    props: {
        service_id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            original: null,
            service: {
                name: null,
                price: null,
                duration: null,
                items: null,
            },
            is_saving: false,
            is_loading_original: false,
        }
    },
    validations: {
        service: {
            name: {required},
            price: {required},
            duration: {required},
            items: {},
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('services-update-modal', status);
        },
        save() {
            this.$v.service.$touch();
            if (this.$v.service.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            let payload = {
                name: this.service.name,
                price: this.service.price,
                duration: this.service.duration,
                items: this.service.items,
            }

            payload.duration = this.$moment(payload.duration).format('HH:mm')


            // if (this.original.attributes.name !== this.$v.service.name.$model)
            //     payload.name = this.$v.service.name.$model;
            //
            // if (this.original.attributes.price !== this.$v.service.price.$model)
            //     payload.price = this.$v.service.price.$model;
            //
            // if (this.original.attributes.items !== this.$v.service.items.$model)
            //     payload.items = this.$v.service.items.$model;

            this.$axios.patch(`services/${this.service_id}`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('services.success_updated'),
                    type: 'success',
                });

                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('services.error_update')),
                    type: 'error',
                });
            });
        },
        populate() {
            if (!this.original || !this.service)
                return;

            this.$v.service.name.$model = this.original.attributes.name;
            this.$v.service.price.$model = this.original.attributes.price;
            this.original.attributes.duration = this.$moment(this.original.attributes.duration, 'HH:mm').format('YYYY-MM-DD HH:mm');
            this.$v.service.duration.$model = this.original.attributes.duration;
            this.$v.service.items.$model = this.original.attributes.items;
        },
        async retrieveOriginalService() {
            this.is_loading_original = false;
            await this.$axios.get(`services/${this.service_id}`)
                .then(({data}) => {
                    this.is_loading_original = false;
                    this.original = data.data;
                })
                .catch(e => {
                    this.is_loading_original = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('service.error_retrieve')),
                        type: 'error',
                    });
                });
        },
    },
    async mounted() {
        await this.retrieveOriginalService();
        this.populate();
    }
}
</script>
