<template>
    <div class="therapists-create-modal">
        <ModalContainer :title="$t('therapists.add_therapist')" identifier="therapists-create-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormGroupTwo>
                    <FormInputText v-model="$v.therapist.name.$model" identifier="name" :label="$t('therapists.name')"
                                   :placeholder="$t('therapists.name')" :disabled="is_saving"
                                   :has-error="$v.therapist.name.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.therapist.name.required">
                                {{$t('validation.x_is_required',{x: $t('therapists.name')})}}
                            </p>
                        </template>
                    </FormInputText>

                    <FormInputText v-model="$v.therapist.mobile_number.$model" identifier="mobile_number" :label="$t('therapists.mobile_number')"
                                   :placeholder="$t('therapists.mobile_number')" :disabled="is_saving"
                                   :has-error="$v.therapist.mobile_number.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.therapist.mobile_number.required">
                                {{$t('validation.x_is_required',{x: $t('therapists.mobile_number')})}}
                            </p>
                        </template>
                    </FormInputText>
                </FormGroupTwo>

                <FormInputText v-model="$v.therapist.email.$model" identifier="email" :label="$t('therapists.email')"
                               :placeholder="$t('therapists.email')" :disabled="is_saving"
                               :has-error="$v.therapist.email.$error" autocomplete="off">
                    <template v-slot:errors>
                        <p v-if="!$v.therapist.email.required">
                            {{$t('validation.x_is_required',{x: $t('therapists.email')})}}
                        </p>
                        <p v-else-if="!$v.therapist.email.email">
                            {{$t('auth.enter_valid_email')}}
                        </p>
                    </template>
                </FormInputText>

                <FormInputPassword v-model="$v.therapist.password.$model" identifier="password" :label="$t('therapists.password')"
                                   :placeholder="$t('therapists.password')" :disabled="is_saving" type="password"
                                   :has-error="$v.therapist.password.$error" autocomplete="new-password">
                    <template v-slot:errors>
                        <p v-if="!$v.therapist.password.required">
                            {{$t('validation.x_is_required',{x: $t('therapists.password')})}}
                        </p>
                    </template>
                </FormInputPassword>

                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{$t('save')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
    import ModalContainer from "../modal/ModalContainer";
    import Form from "../form/Form";
    import FormInputText from "../form/FormInputText";
    import FormGroupTwo from "../form/FormGroupTwo";
    import FormInputSelect from "../form/FormInputSelect";
    import {required, email} from 'vuelidate/lib/validators'
    import Button from "../Button";
    import FormInputPassword from "@/components/form/FormInputPassword";

    export default {
        name: "TherapistsCreateModal",
        components: {FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
        data() {
            return {
                therapist: {
                    name: null,
                    mobile_number: null,
                    email: null,
                    password: null,
                },
                is_saving: false,
            }
        },
        validations: {
            therapist: {
                name: {required},
                mobile_number: {required},
                email: {required, email},
                password: {required},
            }
        },
        methods: {
            close(status) {
                this.$modal.hide('therapists-create-modal', status);
            },
            save() {
                this.$v.therapist.$touch();
                if (this.$v.therapist.$anyError || this.is_saving)
                    return;

                this.is_saving = true;

                const payload = Object.assign({}, this.$v.therapist.$model);

                this.$axios.post(`therapists`, payload).then(({data}) => {
                    this.$notify({
                        text: this.$t('therapists.success_created'),
                        type: 'success',
                    });

                    this.is_saving = false;
                    this.close(true);
                }).catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('therapists.error_create')),
                        type: 'error',
                    });
                });
            },
        },
    }
</script>
