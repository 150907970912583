<template>
    <div class="rooms-create-modal">
        <ModalContainer :title="$t('rooms.add_room')" identifier="rooms-create-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                    <FormInputText v-model="$v.room.name.$model" :label="$t('rooms.name')"
                                   :placeholder="$t('rooms.name')" :disabled="is_saving"
                                   :has-error="$v.room.name.$error" autocomplete="off">
                        <template v-slot:errors>
                            <p v-if="!$v.room.name.required">
                                {{ $t('validation.x_is_required', {x: $t('rooms.name')}) }}
                            </p>
                        </template>
                    </FormInputText>

                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{ $t('save') }}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";

export default {
    name: "RoomsCreateModal",
    components: {Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
    data() {
        return {
            room: {
                name: null,
            },
            is_saving: false,
        }
    },
    validations: {
        room: {
            name: {required},
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('rooms-create-modal', status);
        },
        save() {
            this.$v.room.$touch();
            if (this.$v.room.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            const payload = Object.assign({}, this.$v.room.$model);

            if(!this.room.items)
                delete payload.items;

            this.$axios.post(`rooms`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('rooms.success_created'),
                    type: 'success',
                });

                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('rooms.error_create')),
                    type: 'error',
                });
            });
        },
    },
}
</script>
