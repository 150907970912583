import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';
import RolesIndex from "../views/roles/Index";
import ClientsIndex from "../views/clients/Index";
import TherapistsIndex from "../views/therapists/Index";
import ServicesIndex from "../views/services/Index";
import UsersIndex from "../views/users/Index";
import RoomsIndex from "../views/rooms/Index"
import BookingsIndex from "../views/bookings/Index"
import BookingsUpdate from "../views/bookings/Update"
import CalendarIndex from "../views/calendar/Index"
import Login from "../views/Login";
import NotFound from "../views/NotFound";
import Profile from "../views/Profile";
import {i18n} from '../locale/i18n';
import Reset from "@/views/Reset";
import Register from "../views/Register";
import Confirmation from "@/views/bookings/Confirmation";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: '/bookings'
    },
    {
        path: '/bookings',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'bookings-index',
                component: BookingsIndex,
                meta: {
                    auth: true,
                    all_permissions: ['read bookings'],
                },
            },
            {
                path: ':id',
                name: 'bookings-update',
                component: BookingsUpdate,
                meta: {
                    auth: true,
                    all_permissions: ['read bookings'],
                },
            },
            {
                path: ':id/confirm',
                name: 'booking-confirmation',
                component: Confirmation,
                auth: false
            }
        ]
    },
    {
        path: '/calendar',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'calendar',
                component: CalendarIndex,
                meta: {
                    auth: true,
                    all_permissions: ['read bookings'],
                },
            },
        ]
    },
    {
        path: '/clients',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'clients-index',
                component: ClientsIndex,
                meta: {
                    auth: true,
                    all_permissions: ['read clients'],
                },
            },
        ]
    },
    {
        path: '/therapists',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'therapists-index',
                component: TherapistsIndex,
                meta: {
                    auth: true,
                    all_permissions: ['read users'],
                },
            },
        ]
    },
    {
        path: '/services',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'services-index',
                component: ServicesIndex,
                meta: {
                    auth: true,
                    all_permissions: ['read services'],
                },
            },
        ]
    },
    {
        path: '/rooms',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'rooms-index',
                component: RoomsIndex,
                meta: {
                    auth: true,
                    all_permissions: ['read rooms'],
                },
            },
        ]
    },
    {
        path: '/roles',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'roles-index',
                component: RolesIndex,
                meta: {
                    auth: true,
                    all_permissions: ['read roles'],
                },
            },
        ]
    },
    {
        path: '/users',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'users-index',
                component: UsersIndex,
                meta: {
                    auth: true,
                    all_permissions: ['read users'],
                },
            },
        ]
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
            auth: true,
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            auth: 'guest',
        }
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        meta: {
            auth: 'guest',
        }
    },
    {
        path: '/password/reset',
        name: 'reset',
        component: Reset,
        meta: {
            auth: 'guest',
        }
    },
    {
        path: '*',
        name: 'notfound',
        component: NotFound,
        meta: {
            auth: false
        }
    },
];

const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach(async (to, from, next) => {
    window.scrollTo(0, 0);

    if (!to.meta)
        next();

    // check auth
    if (to.meta.auth) {
        const routeAuth = to.meta.auth;

        if (routeAuth === true) {
            // user must be logged in
            if (!store.getters.token) {
                Vue.notify({
                    type: 'error',
                    text: i18n.t('auth.unauthenticated')
                });

                return next({name: 'login'});
            }
        } else if (routeAuth.toLowerCase() === 'guest') {
            // user must be logged out
            if (store.getters.token)
                return next({path: '/'});
        }
    }

    // check permission
    if (to.meta.any_permissions) {
        // check that the user has some of the required permissions

        if (!store.getters.hasAnyPermission(to.meta.any_permissions)) {
            Vue.notify({
                type: 'error',
                text: i18n.t('auth.unauthorized')
            });

            return next({path: '/'});
        }
    }

    // check permission
    if (to.meta.all_permissions) {
        // check that the user has all the required permissions

        let permissions = to.meta.all_permissions;
        if (!store.getters.hasAllPermissions(permissions)) {
            Vue.notify({
                type: 'error',
                text: i18n.t('auth.unauthorized')
            });

            return next({path: '/'});
        }
    }

    return next();
});

export default router;
