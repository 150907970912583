<template>
    <loading-screen v-if="is_loading"/>
    <div class="bookings-update-modal" v-else-if="!is_loading">
        <ModalContainer :title="$t('bookings.edit_booking')" identifier="bookings-update-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormGroupTwo>
                    <FormInputSelect v-model="$v.booking.client.$model" identifier="client"
                                     :label="$t('bookings.client')" :options="clientOptions"
                                     :placeholder="$t('bookings.client')" :disabled="is_loading_clients || is_saving"
                                     :has-error="$v.booking.client.$error" track-by="id"
                                     :display-custom-label="(row) => `${row.attributes.name} - ${row.attributes.mobile_number}`"
                                     :multiple="false" class="select">
                        <template v-slot:errors>
                            <p v-if="!$v.booking.client.required">
                                {{ $t('validation.x_are_required', {x: $t('bookings.client')}) }}
                            </p>
                        </template>
                    </FormInputSelect>

                    <FormInputSelect v-model="$v.booking.therapist.$model" identifier="therapist"
                                     :label="$t('bookings.therapist')" :options="therapistOptions"
                                     :placeholder="$t('bookings.therapist')" :disabled="is_loading_therapists || is_saving"
                                     :has-error="$v.booking.therapist.$error" track-by="id"
                                     display-label="name" :multiple="false" class="select">
                        <template v-slot:errors>
                            <p v-if="!$v.booking.therapist.required">
                                {{ $t('validation.x_is_required', {x: $t('bookings.therapist')}) }}
                            </p>
                        </template>
                    </FormInputSelect>

                    <FormInputDateTime v-model="$v.booking.from.$model" identifier="from"
                                       :label="$t('bookings.date_time_from')"
                                       :placeholder="$t('bookings.date_time_from')"
                                       :disabled="is_saving" :minute-interval="15"
                                       :has-error="$v.booking.from.$error || is_time_equal || is_date_different"
                                       :min-date="todayDate" @input="() => setToDate()">
                        <template v-slot:errors>
                            <p v-if="!$v.booking.from.required">
                                {{ $t('validation.x_is_required', {x: $t('bookings.date_time_from')}) }}
                            </p>
                            <p v-else-if="is_time_equal">
                                {{ $t('bookings.time_cant_be_same') }}
                            </p>
                            <p v-else-if="is_date_different">
                                {{ $t('bookings.date_cant_be_different') }}
                            </p>
                        </template>
                    </FormInputDateTime>
                    <FormInputDateTime v-model="$v.booking.to.$model" identifier="to" :label="$t('bookings.date_time_to')"
                                       :placeholder="$t('bookings.date_time_to')"
                                       :disabled="true" :hasError="$v.booking.to.$error" :min-date="minReturnDate"
                                       :max-date="maxReturnDate">
                        <template v-slot:errors>
                            <p v-if="!$v.booking.to.required">
                                {{ $t('validation.x_is_required', {x: $t('bookings.date_time_to')}) }}
                            </p>
                        </template>
                    </FormInputDateTime>

                    <FormInputSelect v-model="$v.booking.service.$model" identifier="service"
                                     :label="$t('bookings.service')" :options="serviceOptions"
                                     :placeholder="$t('bookings.service')" :disabled="is_loading_services || is_saving"
                                     :has-error="$v.booking.service.$error" track-by="id"
                                     display-label="name" :multiple="false" class="select" @input="() => setToDate()">
                        <template v-slot:errors>
                            <p v-if="!$v.booking.service.required">
                                {{ $t('validation.x_are_required', {x: $t('bookings.service')}) }}
                            </p>
                        </template>
                    </FormInputSelect>

                    <FormInputSelect v-model="$v.booking.room.$model" identifier="room"
                                     :label="$t('bookings.room')" :options="roomOptions"
                                     :placeholder="$t('bookings.room')" :disabled="is_loading_rooms || is_saving"
                                     :has-error="$v.booking.room.$error" track-by="id"
                                     display-label="name" :multiple="false" class="select">
                        <template v-slot:errors>
                            <p v-if="!$v.booking.room.required">
                                {{ $t('validation.x_are_required', {x: $t('bookings.room')}) }}
                            </p>
                        </template>
                    </FormInputSelect>
                </FormGroupTwo>

                <FormInputText v-model="$v.booking.note.$model" :label="$t('bookings.note')"
                               :placeholder="$t('bookings.note')" :disabled="is_saving"
                               :use-textarea="true" :large-textarea="true"
                               :has-error="$v.booking.note.$error" autocomplete="off">
                    <template v-slot:errors>
                    </template>
                </FormInputText>

                <div class="footer-container">
                    <div class="status-select-container">
                        <p>{{ $t('bookings.status') }}:</p>
                        <FormInputSelect v-model="$v.booking.status.$model" :placeholder="$t('bookings.status')"
                                         :allow-empty="false" :options="bookingStatusOptions" className="filter"
                                         identifier="name" :disabled="is_saving" track-by="value" displayLabel="text"/>
                    </div>

                    <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                        {{ $t('save') }}
                    </Button>
                </div>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import FormInputDateTime from "../form/FormInputDateTime";
import {required} from 'vuelidate/lib/validators'
import Button from "../Button";
import LoadingScreen from "@/components/LoadingScreen.vue";

export default {
    name: "BookingsUpdateModal",
    components: {
        LoadingScreen,
        Button, FormInputSelect, FormInputDateTime, FormGroupTwo, FormInputText, Form, ModalContainer},
    props: {
        booking_id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            booking: {
                client: null,
                therapist: null,
                from: null,
                to: null,
                service: null,
                room: null,
                note: null,
                status: null,
            },
            clientOptions: [],
            therapistOptions: [],
            serviceOptions: [],
            roomOptions: [],
            bookingStatusOptions: [
                {text: this.$t('bookings.pending'), value: 'pending'},
                {text: this.$t('bookings.canceled'), value: 'canceled'},
                {text: this.$t('bookings.confirmed'), value: 'confirmed'}],
            is_loading: true,
            is_loading_clients: false,
            is_loading_therapists: false,
            is_loading_services: false,
            is_loading_rooms: false,
            is_saving: false,
            is_time_equal: false,
            is_date_different: false,
        }
    },
    validations: {
        booking: {
            client: {required},
            therapist: {required},
            from: {required},
            to: {required},
            service: {required},
            room: {required},
            note: {},
            status: {required},
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('bookings-update-modal', status);
        },
        setToDate() {
            if (!this.booking.from || !this.booking.service || !this.booking.service.duration) return
            let tempTime =  this.$moment(this.booking.service.duration, 'HH:mm')
            this.booking.to = this.$moment(this.booking.from).add(tempTime.hours(), 'hours').add(tempTime.minutes(), 'minutes').format('YYYY-MM-DD HH:mm')
        },
        save() {
            this.is_time_equal = false;
            this.is_date_different = false;
            this.$v.booking.$touch();
            if (this.$v.booking.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            const payload = {}

            payload.from = this.booking.from
            payload.to = this.booking.to
            payload.status = this.booking.status.value
            payload.client_id = this.booking.client.id
            payload.service_id = this.booking.service.id
            payload.room_id = this.booking.room.id
            payload.therapist_id = this.booking.therapist.id

            if (payload.from === payload.to) {
                this.is_time_equal = true;
                this.is_saving = false;
                return;
            }

            if (payload.from.substring(0, 10) !== payload.to.substring(0, 10)) {
                this.is_date_different = true;
                this.is_saving = false;
                return;
            }

            if (this.booking.note)
                payload.note = this.booking.note

            this.$axios.patch(`bookings/${this.booking_id}`, payload).then(({}) => {
                this.$notify({
                    text: this.$t('bookings.success_created'),
                    type: 'success',
                });

                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('bookings.error_create')),
                    type: 'error',
                });
            });
        },
        async retrieveClients() {
            this.is_loading_clients = true;

            await this.$axios.get('clients/list')
                .then(({data}) => {
                    this.clientOptions = data.data;
                    this.is_loading_clients = false;
                })
                .catch(e => {
                    this.is_loading_clients = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('clients.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async retrieveTherapists() {
            this.is_loading_therapists = true;

            await this.$axios.get('therapists/list')
                .then(({data}) => {
                    this.therapistOptions = data.data.map(x => ({
                        id: x.id,
                        name: x.attributes.name,
                        column: x.attributes.column
                    }))
                    this.is_loading_therapists = false;
                })
                .catch(e => {
                    this.is_loading_therapists = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('therapists.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async retrieveServices() {
            this.is_loading_services = true;

            await this.$axios.get('services/list')
                .then(({data}) => {
                    this.serviceOptions = data.data.map(x => ({
                        id: x.id,
                        name: x.attributes.name,
                        column: x.attributes.column,
                        duration: x.attributes?.duration
                    }))
                    this.is_loading_services = false;
                })
                .catch(e => {
                    this.is_loading_services = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('services.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async retrieveRooms() {
            this.is_loading_rooms = true;

            await this.$axios.get('rooms/list')
                .then(({data}) => {
                    this.roomOptions = data.data.map(x => ({
                        id: x.id,
                        name: x.attributes.name,
                        column: x.attributes.column
                    }))
                    this.is_loading_rooms = false;
                })
                .catch(e => {
                    this.is_loading_rooms = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('rooms.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async retrieveOriginalBooking() {
            await this.$axios.get(`bookings/${this.booking_id}`)
                .then(({data}) => {
                    this.original = data.data;
                })
                .catch(e => {
                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('bookings.error_retrieve_booking')),
                        type: 'error',
                    });
                });
        },
        async populate() {
            if (!this.original) return;

            this.$v.booking.client.$model = this.clientOptions.find(x => x.id === this.original.relationships.client.id)
            this.$v.booking.therapist.$model = this.therapistOptions.find(x => x.id === this.original.relationships.therapist.id)
            this.$v.booking.from.$model = this.$moment(this.original.attributes.from).format('YYYY-MM-DD HH:mm');
            this.$v.booking.to.$model = this.$moment(this.original.attributes.to).format('YYYY-MM-DD HH:mm');
            this.$v.booking.service.$model = this.serviceOptions.find(x => x.id === this.original.relationships.service.id)
            this.$v.booking.room.$model = this.roomOptions.find(x => x.id === this.original.relationships.room.id)
            this.$v.booking.status.$model = this.bookingStatusOptions.find(x => x.value === this.original.attributes.status)

            if (this.original.attributes.note)
                this.$v.booking.note.$model = this.original.attributes.note;
        },
    },
    computed: {
        todayDate() {
            let date = new Date();
            return this.$moment(date).format('YYYY-MM-DD');
        },
        minReturnDate() {
            return this.$moment(this.booking.from).add(15, 'minutes').format('YYYY-MM-DD HH:mm');
        },
        maxReturnDate() {
            return this.$moment(this.booking.from).endOf('day').format('YYYY-MM-DD HH:mm');
        }
    },
    async mounted() {
        this.is_loading = true;
        await Promise.all([this.retrieveClients(), this.retrieveTherapists(), this.retrieveServices(), this.retrieveRooms(),this.retrieveOriginalBooking()]);
        await this.populate();
        this.is_loading = false;
    },
}
</script>

<style lang="scss" scoped>
.footer-container {
    @apply flex flex-row justify-between;

    .status-select-container {
        @apply flex flex-row gap-x-4 items-center;

        .input-group {
            @apply w-36 mb-0;
        }
    }
}

</style>