<template>
    <loading-screen v-if="is_loading"/>
    <div class="page-container" v-else="!is_loading">
        <Headbar>
            <template v-slot:left>
                <h1 v-if="original">{{ original.id }}</h1>
            </template>
            <template v-slot:right>

                <div class="status-select-container">
                    <p>{{ $t('bookings.status') }}:</p>
                    <FormInputSelect v-model="$v.booking.status.$model" :placeholder="$t('bookings.status')"
                                     :allow-empty="false" :options="bookingStatusOptions" className="filter"
                                     identifier="name" :disabled="is_saving" track-by="value" displayLabel="text"/>
                </div>

                <Button className="--primary --small --wider"
                        :class="{spinner: is_saving}" :onclick="save"
                        v-if="$store.getters.hasAnyPermission(['update bookings'])">
                    {{ $t('save') }}
                </Button>
            </template>
        </Headbar>
        <main>
            <div class="booking-details-container">
                <Form class="form" :disabled="!$store.getters.hasPermission('update bookings') || is_saving">
                    <SectionHeader :title="$t('bookings.booking_details')"></SectionHeader>
                    <div class="form-body">
                        <div class="form-columns-info">
                            <FormGroupTwo>
                                <FormInputSelect v-model="$v.booking.client.$model" identifier="client"
                                                 :label="$t('bookings.client')" :options="clientOptions"
                                                 :placeholder="$t('bookings.client')" :disabled="is_saving"
                                                 :has-error="$v.booking.client.$error" track-by="id"
                                                 :display-custom-label="(row) => `${row.attributes.name} - ${row.attributes.mobile_number}`"
                                                 :multiple="false" class="select">
                                    <template v-slot:errors>
                                        <p v-if="!$v.role.client.required">
                                            {{ $t('validation.x_are_required', {x: $t('bookings.client')}) }}
                                        </p>
                                    </template>
                                </FormInputSelect>

                                <FormInputSelect v-model="$v.booking.therapist.$model" identifier="therapist"
                                                 :label="$t('bookings.therapist')" :options="therapistOptions"
                                                 :placeholder="$t('bookings.therapist')" :disabled="is_saving"
                                                 :has-error="$v.booking.therapist.$error" track-by="id"
                                                 display-label="name" :multiple="false" class="select">
                                    <template v-slot:errors>
                                        <p v-if="!$v.booking.therapist.required">
                                            {{ $t('validation.x_are_required', {x: $t('bookings.therapist')}) }}
                                        </p>
                                    </template>
                                </FormInputSelect>

                                <FormInputDateTime v-model="$v.booking.from.$model" identifier="from"
                                                   :label="$t('bookings.date_time_from')"
                                                   :placeholder="$t('bookings.date_time_from')"
                                                   :disabled="is_saving" :minute-interval="15"
                                                   :has-error="$v.booking.from.$error || is_time_equal || is_date_different"
                                                   :min-date="todayDate" @input="() => setToDate()">
                                    <template v-slot:errors>
                                        <p v-if="!$v.booking.from.required">
                                            {{ $t('validation.x_is_required', {x: $t('bookings.date_time_from')}) }}
                                        </p>
                                        <p v-else-if="is_time_equal">
                                            {{ $t('bookings.time_cant_be_same') }}
                                        </p>
                                        <p v-else-if="is_date_different">
                                            {{ $t('bookings.date_cant_be_different') }}
                                        </p>
                                    </template>
                                </FormInputDateTime>
                                <FormInputDateTime v-model="$v.booking.to.$model" identifier="to" :label="$t('bookings.date_time_to')"
                                                   :placeholder="$t('bookings.date_time_to')"
                                                   :disabled="true" :hasError="$v.booking.to.$error" :min-date="minReturnDate"
                                                   :max-date="maxReturnDate">
                                    <template v-slot:errors>
                                        <p v-if="!$v.booking.to.required">
                                            {{ $t('validation.x_is_required', {x: $t('bookings.date_time_to')}) }}
                                        </p>
                                    </template>
                                </FormInputDateTime>

                                <FormInputSelect v-model="$v.booking.service.$model" identifier="service"
                                                 :label="$t('bookings.service')" :options="serviceOptions"
                                                 :placeholder="$t('bookings.service')" :disabled="is_saving"
                                                 :has-error="$v.booking.service.$error" track-by="id"
                                                 display-label="name" :multiple="false" class="select" @input="() => setToDate()">
                                    <template v-slot:errors>
                                        <p v-if="!$v.role.service.required">
                                            {{ $t('validation.x_are_required', {x: $t('bookings.service')}) }}
                                        </p>
                                    </template>
                                </FormInputSelect>

                                <FormInputSelect v-model="$v.booking.room.$model" identifier="room"
                                                 :label="$t('bookings.room')" :options="roomOptions"
                                                 :placeholder="$t('bookings.room')" :disabled="is_saving"
                                                 :has-error="$v.booking.room.$error" track-by="id"
                                                 display-label="name" :multiple="false" class="select">
                                    <template v-slot:errors>
                                        <p v-if="!$v.role.room.required">
                                            {{ $t('validation.x_are_required', {x: $t('bookings.room')}) }}
                                        </p>
                                    </template>
                                </FormInputSelect>
                            </FormGroupTwo>
                        </div>

                        <div class="form-columns-note">
                            <FormInputText class="note-area" v-model="$v.booking.note.$model" :label="$t('bookings.note')"
                                           :placeholder="$t('bookings.note')" :disabled="is_saving"
                                           :use-textarea="true" :large-textarea="true"
                                           :has-error="$v.booking.note.$error" autocomplete="off">
                                <template v-slot:errors>
                                </template>
                            </FormInputText>
                            <FormInputText v-model="original.relationships.user.attributes.name"
                                           :label="$t('bookings.booking_by')"
                                           :placeholder="$t('bookings.booking_by')" :disabled="true">
                            </FormInputText>
                        </div>

                    </div>
                </Form>
            </div>
            <div class="audits-container">
                <div class="title-container">
                    <h1>{{ $t('audits.audits') }}</h1>
                </div>
                <vue-good-table
                    mode="remote"
                    styleClass="vgt-table vgt-custom"
                    :columns="auditsColumns"
                    :rows="audits"
                    :isLoading.sync="is_loading_audits"
                    :search-options="{enabled: false}"
                    :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    dropdownAllowAll: false,
                    perPage: 15,
                    perPageDropdownEnabled: false,
                    rowsPerPageLabel: $t('x_per_page', {x: $t('audits.audits')}),
                }"
                    :sort-options="{
                  enabled: true,
                  multipleColumns: true,
                }"
                    :totalRows="totalRecordsAudits"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange">
                    <template slot="table-row" slot-scope="props">
                        <div v-if="props.column.field === 'status'">
                            <div class="not-available-container">
                                <div class="not-available-circle"></div>
                                <p>{{$t('profile.not_available')}}</p>
                            </div>
                        </div>
                        <div v-else-if="props.column.field === 'attributes.event'">
                            <p style="text-transform: capitalize;">{{ props.row.attributes.event}}</p>
                        </div>
                        <div v-else-if="props.column.field === 'values'" class="td-values">
                            <div v-if="Object.keys(props.row.attributes.old_values).length" class="row">
                                <p class="name">{{$t('bookings.old_values')}}</p>
                                <div class="values">
                                    <p style="text-transform: capitalize" v-for="key in Object.keys(props.row.attributes.old_values)"><strong>{{$t(`bookings.${key}`)}}: </strong>{{props.row.attributes.old_values[key]}}</p>
                                </div>
                            </div>
                            <div v-if="Object.keys(props.row.attributes.new_values).length" class="row">
                                <p class="name">{{$t('bookings.new_values')}}</p>
                                <div class="values">
                                    <p style="text-transform: capitalize" v-for="key in Object.keys(props.row.attributes.new_values)"><strong>{{$t(`bookings.${key}`)}}: </strong>{{props.row.attributes.new_values[key]}}</p>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="props.column.field === 'attributes.created_at'">
                            <p style="text-transform: capitalize;">{{ $moment(props.row.attributes.created_at).format('DD/MM/YYYY - HH:mm') }}</p>
                        </div>
                        <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                    </template>
                </vue-good-table>
            </div>
        </main>
    </div>
</template>

<script>
import _ from 'lodash';
import Headbar from "../../components/headbar/Headbar";
import Form from "../../components/form/Form";
import SectionHeader from "../../components/SectionHeader";
import Button from "../../components/Button";
import FormInputText from "../../components/form/FormInputText";
import FormInputSelect from "../../components/form/FormInputSelect";
import FormInputDateTime from "../../components/form/FormInputDateTime";
import {required, email} from 'vuelidate/lib/validators'
import FormGroupTwo from "../../components/form/FormGroupTwo";
import LoadingScreen from "@/components/LoadingScreen";

export default {
    name: "bookings-update-page",
    components: {
        LoadingScreen,
        FormGroupTwo,
        FormInputText,
        FormInputSelect,
        FormInputDateTime,
        Button,
        SectionHeader,
        Form,
        Headbar,
    },
    data: function () {

        return {
            original: null,
            audits: [],
            booking: {
                client: null,
                therapist: null,
                from: null,
                to: null,
                service: null,
                room: null,
                note: null,
                status: null,
            },
            clientOptions: [],
            therapistOptions: [],
            serviceOptions: [],
            roomOptions: [],
            totalRecordsAudits: null,
            serverParams: {sorting: []},
            bookingStatusOptions: [
                {text: this.$t('bookings.pending'), value: 'pending'},
                {text: this.$t('bookings.canceled'), value: 'canceled'},
                {text: this.$t('bookings.confirmed'), value: 'confirmed'}],
            auditsColumns: [
                {
                    label: this.$t('audits.user'),
                    field: 'relationships.user.data.attributes.name',
                    sortable: false,
                },
                {
                    label: this.$t('audits.event'),
                    field: 'attributes.event',
                    sortable: false,
                },
                {
                    label: this.$t('audits.detail'),
                    field: 'values',
                    sortable: false,
                },
                {
                    label: this.$t('audits.date_time'),
                    field: 'attributes.created_at',
                    sortable: false,
                },
            ],
            is_loading: true,
            is_loading_audits: false,
            is_loading_clients: false,
            is_loading_therapists: false,
            is_loading_services: false,
            is_loading_rooms: false,
            is_saving: false,
            is_time_equal: false,
            is_date_different: false,
        }
    },
    validations: {
        booking: {
            client: {required},
            therapist: {required},
            from: {required},
            to: {required},
            service: {required},
            room: {required},
            note: {},
            status: {required},
        }
    },
    methods: {
        setToDate() {
            if (!this.booking.from || !this.booking.service || !this.booking.service.duration) return
            let tempTime =  this.$moment(this.booking.service.duration, 'HH:mm')
            this.booking.to = this.$moment(this.booking.from).add(tempTime.hours(), 'hours').add(tempTime.minutes(), 'minutes').format('YYYY-MM-DD HH:mm')
        },
        save() {
            this.$v.booking.$touch();
            if (this.$v.booking.$anyError || this.is_saving)
                return;

            const payload = {}

            payload.from = this.booking.from
            payload.to = this.booking.to
            payload.status = this.booking.status.value
            payload.client_id = this.booking.client.id
            payload.service_id = this.booking.service.id
            payload.room_id = this.booking.room.id
            payload.therapist_id = this.booking.therapist.id


            if (payload.from === payload.to) {
                this.is_time_equal = true;
                this.is_saving = false;
                return;
            }

            if (payload.from.substring(0, 10) !== payload.to.substring(0, 10)) {
                this.is_date_different = true;
                this.is_saving = false;
                return;
            }

            if (!this.booking.note)
                payload.note = null

            this.is_saving = true;

            this.$axios.patch(`bookings/${this.$route.params.id}`, payload).then(async ({data}) => {
                this.is_saving = false

                this.$notify({
                    text: this.$t('bookings.success_updated'),
                    type: 'success',
                });
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('bookings.error_update')),
                    type: 'error',
                });
            });
        },
        async retrieveClients() {
            this.is_loading_clients = true;

            await this.$axios.get('clients/list')
                .then(({data}) => {
                    this.clientOptions = data.data;
                    this.is_loading_clients = false;
                })
                .catch(e => {
                    this.is_loading_clients = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('clients.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async retrieveTherapists() {
            this.is_loading_therapists = true;

            await this.$axios.get('therapists/list')
                .then(({data}) => {
                    this.therapistOptions = data.data.map(x => ({
                        id: x.id,
                        name: x.attributes.name,
                        column: x.attributes.column
                    }))
                    this.is_loading_therapists = false;
                })
                .catch(e => {
                    this.is_loading_therapists = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('therapists.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async retrieveServices() {
            this.is_loading_services = true;

            await this.$axios.get('services/list')
                .then(({data}) => {
                    this.serviceOptions = data.data.map(x => ({
                        id: x.id,
                        name: x.attributes.name,
                        column: x.attributes.column,
                        duration: x.attributes?.duration

                    }))
                    this.is_loading_services = false;
                })
                .catch(e => {
                    this.is_loading_services = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('services.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async retrieveRooms() {
            this.is_loading_rooms = true;

            await this.$axios.get('rooms/list')
                .then(({data}) => {
                    this.roomOptions = data.data.map(x => ({
                        id: x.id,
                        name: x.attributes.name,
                        column: x.attributes.column
                    }))
                    this.is_loading_rooms = false;
                })
                .catch(e => {
                    this.is_loading_rooms = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('rooms.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async retrieveOriginalBooking() {
            await this.$axios.get(`bookings/${this.$route.params.id}`)
                .then(({data}) => {
                    this.original = data.data
                })
                .catch(e => {
                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('bookings.error_retrieve_booking')),
                        type: 'error',
                    });
                });
        },
        async populate() {
            if (!this.original) return;

            this.$v.booking.client.$model = this.clientOptions.find(x => x.id === this.original.relationships.client.id)
            this.$v.booking.therapist.$model = this.therapistOptions.find(x => x.id === this.original.relationships.therapist.id)
            this.$v.booking.from.$model = this.$moment(this.original.attributes.from).format('YYYY-MM-DD HH:mm');
            this.$v.booking.to.$model = this.$moment(this.original.attributes.to).format('YYYY-MM-DD HH:mm');
            this.$v.booking.service.$model = this.serviceOptions.find(x => x.id === this.original.relationships.service.id)
            this.$v.booking.room.$model = this.roomOptions.find(x => x.id === this.original.relationships.room.id)
            this.$v.booking.status.$model = this.bookingStatusOptions.find(x => x.value === this.original.attributes.status)
            if (this.original.attributes.note)
                this.$v.booking.note.$model = this.original.attributes.note;
        },
        async retrieveAudits() {
            this.is_loading_audits = true;
            const encodedSorting = this.serverParams.sorting.map(f => btoa(JSON.stringify(f)));

            await this.$axios.get(`bookings/${this.$route.params.id}/audits`, {params: {...this.serverParams, sorting: encodedSorting}})
                .then(({data}) => {
                    this.audits = data.data
                    this.totalRecordsAudits = data.meta.total;
                    this.is_loading_audits = false
                })
                .catch(e => {
                    this.is_loading_audits = false
                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('bookings.error_retrieve_audits')),
                        type: 'error',
                    });
                });
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.retrieveAudits();
        },
        onSortChange(params) {
            const sorts = [];

            if (!params || !params.length) {
                this.updateParams({sorting: []});
                return this.retrieveAudits();
            }

            params.forEach(p => {
                if (!p.type || p.type === 'none')
                    return;

                let sort_by = null;
                let sort_order = p.type;
                if (p.field === 'attributes.name')
                    sort_by = 'name';
                else if (p.field === 'attributes.email')
                    sort_by = 'email';
                else if (p.field === 'role')
                    sort_by = 'role';
                else
                    sort_by = p.field.split('.')[1];

                sorts.push({sort_order, sort_by})
            });

            this.updateParams({sorting: sorts});
            this.retrieveAudits();
        },
        removeParam(param) {
            this.$delete(this.serverParams, param);
        },
    },
    computed: {
        todayDate() {
            let date = new Date();
            return this.$moment(date).format('YYYY-MM-DD');
        },
        minReturnDate() {
            return this.$moment(this.booking.from).add(15, 'minutes').format('YYYY-MM-DD HH:mm');
        },
        maxReturnDate() {
            return this.$moment(this.booking.from).endOf('day').format('YYYY-MM-DD HH:mm');
        }
    },
    async mounted() {
        this.is_loading = true;
        await this.retrieveClients();
        await this.retrieveTherapists();
        await this.retrieveServices();
        await this.retrieveRooms();
        await this.retrieveOriginalBooking();
        await this.populate();
        await this.retrieveAudits();
        this.is_loading = false;
    },
    head() {
        return {
            title: {
                inner: this.$t('bookings.booking')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
main {
    padding: 0 !important;
}

.status-select-container {
    @apply flex flex-row gap-x-4 items-center;

    .input-group {
        @apply w-36 mb-0;
    }
}

.booking-details-container {
    @apply border-b-2 border-primary w-full py-7 px-6;

    @screen md {
        @apply px-9;
    }

    .form {
        @apply w-full ml-0;

        @screen xl {
            @apply w-8/12;
        }

        .form-body {
            @apply pt-5 px-5 pb-2 flex flex-col;

            @screen lg {
                @apply flex-row;
            }

            .form-columns-info {
                @apply w-full;

                @screen lg {
                    @apply w-2/3;
                }
            }

            .form-columns-note {
                @apply w-full;

                @screen lg {
                    @apply ml-4 w-1/3;
                }

                & > .note-area {
                    height: 10.1rem !important;
                }
            }
        }
    }
}

.audits-container {
    @apply w-full py-7 px-6;

    @screen md {
        @apply px-9;
    }

    .title-container {
        @apply flex flex-row justify-between mb-4;

        h1 {
            @apply text-black font-bold text-2xl;
        }
    }

    .not-available-container {
        @apply flex flex-row items-center gap-x-2;

        .not-available-circle {
            @apply bg-black w-4 h-4 rounded-full;
        }
    }

    .td-after {
        @apply flex flex-row;

        & > * {
            @apply mr-3;

            &:last-child {
                @apply mr-0;
            }
        }
    }

    .row {
        &:first-of-type {
            @apply mb-2;
        }

        &:last-of-type {
            @apply mb-0;
        }
    }
}
</style>